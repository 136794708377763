import axios from "axios";
import { constant } from "../config/config";

const URL = constant.BACKEND_URL;
const token = constant.BACKEND_TOKEN;

axios.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

export default function ({ method = "GET", url, data = {}, headers = {} }) {
  headers["Authorization"] = `Bearer ${token}`;

  return axios({ method, url: `${URL}/${url}`, data, headers }).then(
    ({ data }) => data
  );
}
