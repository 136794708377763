import { SET_USDT_BALANCE, SET_CURRENCY_BALANCE, SET_ONE_COPY_PRICE, SET_NFT_BALANCE } from "../types/WalletTypes"



// utilized
export const setUsdtBalanceStore = (usdtBalance) => {
    return {
        type: SET_USDT_BALANCE,
        payload: usdtBalance
    }
}
// utilized
export const setCurrencyBalanceStore = (currencyBalance) => {
    return {
        type: SET_CURRENCY_BALANCE,
        payload: currencyBalance
    }
}
// utilized
export const setOneCopyPriceStore = (priceOfCopy) => {
    return {
        type: SET_ONE_COPY_PRICE,
        payload: priceOfCopy
    }
}

export const setNftBalances = (nftBalances) => {
    return {
        type: SET_NFT_BALANCE,
        payload: nftBalances
    }
}

