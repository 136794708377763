import { SET_USDT_BALANCE, SET_CURRENCY_BALANCE, SET_ONE_COPY_PRICE, SET_NFT_BALANCE, SET_LOADING } from "../types/WalletTypes"

let initialState = {
    usdtBalance: 0,
    currencyBalance: 0,
    priceOfOneCopy: 0,
    nftBalances: [],
    loading: false,
}

export const DashboardReducers = (state = initialState, action) => {
    switch (action.type) {
        case SET_USDT_BALANCE:
            return {
                ...state,
                usdtBalance: action.payload
            }
        case SET_CURRENCY_BALANCE:
            return {
                ...state,
                currencyBalance: action.payload
            }
        case SET_ONE_COPY_PRICE:
            return {
                ...state,
                priceOfOneCopy: action.payload
            }
        case SET_NFT_BALANCE:
            return {
                ...state,
                nftBalances: action.payload,
               

            }
        case SET_LOADING:

            return {
                ...state,
                loading: action.payload
            }

        default:
            return state
    }

}





