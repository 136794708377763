import languageStrings from "../../language/index";
// import FontAwesomeIcon from "fontawesome"

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "./footer.css"
import {
  FaFacebookF,
  FaInstagram,
  FaPinterest,
  FaQuora,
  FaLinkedinIn,
  FaDiscord
} from "react-icons/fa";
import { FaMedium, FaXTwitter } from "react-icons/fa6";

export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              {" "}
              <div className="footer-img" style={{ marginBottom: "20px" }}>
                <img
                  className=""
                  style={{ width: "200px" }}
                  src="/assets/images/logo-in-solution.png"
                />
              </div>
              <p
                style={{ color: "white", padding: "10px 0px" }}
                className="text-lg-start text-center footer-text"
              >
                Harvesting sustainable growth
              </p>
              <div className="d-flex justify-content-start align-items-center footer-social">

                <a
                  href="https://discord.com/invite/5TSJe5xzS7/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaDiscord />
                </a>
                <a
                  href="https://www.facebook.com/cloudfarmsofficial/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="https://www.instagram.com/cloudfarms.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
                <a
                  href="https://www.pinterest.com/bananacloudfarms/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaPinterest />
                </a>
                <a
                  href="https://www.quora.com/profile/Cloudfarms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaQuora />
                </a>
                <a
                  href="https://www.linkedin.com/company/cloudfarms-investment/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn />
                </a>
                <a
                  href="https://medium.com/@cloudfarms.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaMedium />
                </a>
                <a
                  href="https://x.com/cloudfarms_"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaXTwitter />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 text-md-start text-center">
              <ul className="footer-ul">
                <li className="">
                  <a href="#sale-section">{languageStrings["Platform"]}</a>
                </li>
                <li className="">
                  <a href="#about-us">About Us</a>
                </li>
                <li className="">
                  <a href="#how-to-buy">{languageStrings["How to Buy"]}</a>
                </li>
                <li className="">
                  <a href="#road-map">{languageStrings["Roadmap"]}</a>
                </li>

                <li className="">
                  <a href="#faq">{languageStrings["FAQ"]}</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-6  col-sm-6 col-12  text-md-start text-center">
              <ul className="social-links">
                <li>
                  <a
                    className=""
                    style={{
                      color: "white",
                      textDecoration: "none",
                    }}
                    href="/assets/policy/Estate-Upcare-Privacy-Policy.pdf"
                    target="_blank"
                  >
                    {languageStrings["Privacy Policy"]}
                  </a>
                </li>
                <li className="socialLinks mr-2">
                  <a
                    style={{
                      color: "white",
                      textDecoration: "none",
                    }}
                    href="/assets/policy/Estate-Upcare-Disclaimer.pdf"
                    target="_blank"
                  >
                    {languageStrings["Disclaimer"]}
                  </a>
                </li>
                <li className="socialLinks">
                  <a
                    style={{
                      color: "white",
                      textDecoration: "none",
                    }}
                    href="/assets/policy/Estate-Upcare-Cookies-policy.pdf"
                    target="_blank"
                  >
                    {languageStrings["cookies"]}
                  </a>
                </li>
                <li className="socialLinks">
                  <a
                    style={{
                      color: "white",
                      textDecoration: "none",
                    }}
                    href="/assets/policy/Estate-Upcare-Terms_Conditions.pdf"
                    target="_blank"
                  >
                    {languageStrings["Terms & Conditions"]}
                  </a>
                </li>
              </ul>
              {/* <div className="d-flex  align-items-center policy  text-center flex-wrap justify-content-center">
                
            
              </div> */}
            </div>
            <hr className="footer-divider" />
            <div className="container footer-text">
              <div className="row">
                <div className="col-lg-12">
                  <p className="text-center footer-text">
                    <span>&copy;</span>{" "}
                    {languageStrings["Copyright 2023, All Rights Reserved"]}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
