import languageStrings from "../../../language/index";

export default function AboutUs() {
  return (
    <>
      <section
        id="about-us"
        className="AboutUs d-flex align-items-center position-relative"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-6">
              <div className="mb-5">
                <div className="banner">
                  <img
                    src="/assets/images/aboutus.png"
                    alt=""
                    className="w-100"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-6">
              <div>
                <div className="content">
                  <h4 className="blackheadingtwo mb-3">
                    {languageStrings["About us"]}
                  </h4>
                  <p className="description mb-5">
                    CloudFarms aims to create a decentralised platform that
                    connects investors to all participating stakeholders in the
                    agriculture so we can create larger and more of client farms
                    generating more revenue from cash crops. Food production is
                    a lucrative business and earning from agriculture dates back
                    as old as time. More recently, however, investing in farming
                    seems out of reach of the average individual and
                    corporations who are looking to diversify their investment
                    portfolio, produce food and compensate for carbon emissions.
                    That’s where CloudFarms uses Blockchain to distribute the
                    ownership and sustainably farm in larger acres across the
                    globe. We identify key crops around the world and bridge the
                    stakeholders to unlock transparent and profitable farming
                    opportunities.
                  </p>
                  <a
                    href="/CloudFarms_WP_ref.pdf" target="_blank"
                    
                    className="btn btn-custom"
                  >
                    {languageStrings["Whitepaper"]}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
