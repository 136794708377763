import isNumber from 'is-number';
import { setNetworkID, setMetamaskConnection, setWallet, saveProvider } from '../redux/action/WalletAction';
import { switchNetwork } from './Blockchain';
import { modalAlert } from './alert';
import store from '../store';
import { constant } from '../config/config';
import { connectMetamask } from './Blockchain';
import { ethers } from "ethers";
import { isEmpty } from "lodash";

export const metamaskConnectionProcess = async () => {
    try {
        if (window.ethereum) {
            let { networkId, accounts, provider } = await connectMetamask();
            if (networkId !== constant.NETWORK_ID) {

                await switchNetwork()
                return false
            }
            store.dispatch(setNetworkID(networkId))
            store.dispatch(setWallet(accounts[0]))
            store.dispatch(saveProvider(provider))
            store.dispatch(setMetamaskConnection(true))
            

        }

        else {
            modalAlert('', 'MetaMask extension not found. Please install MetaMask for a better experience.', 'warning')
            return false
        }
        return true
    }
    catch (error) {
        modalAlert('', error.message, 'error')
        return false
    }

}

//call on page refresh or network switch if metamask connected with our desired network then update the redux
export const metamaskExistAndConnected = async () => {
   
    // Check if MetaMask is available and connected
    try {
        if (typeof window.ethereum !== 'undefined') {


            //     // Request access to the user's MetaMask accounts
            window.ethereum
                .request({ method: 'eth_accounts' })
                .then(accounts => {
                    if (accounts.length > 0) {

                        const ethProvider = new ethers.providers.Web3Provider(window.ethereum);
                        ethProvider.getNetwork().then(async network => {

                            if (network.chainId.toString() === constant.NETWORK_ID) {
                                let { networkId, accounts, provider } = await connectMetamask();
                                store.dispatch(setNetworkID(networkId))
                                store.dispatch(setWallet(accounts[0]))
                                store.dispatch(saveProvider(provider))
                                store.dispatch(setMetamaskConnection(true))

                            }
                            else {
                                store.dispatch(setMetamaskConnection(false))
                            }
                           

                        })
                    } else {
                        store.dispatch(setMetamaskConnection(false))

                    }
                })
                .catch(err => {
                    let msg = err.message ? err.message : "Error checking MetaMask connection:"
                    modalAlert('', msg, 'error')
                    // console.error('Error checking MetaMask connection:', err);
                });
        }
    }
    catch (error) {
        
        // modalAlert('', error.message, 'error')
        // return false
    }
}





