import GifSlider from "./section/GifSlider";
import Sale from "./section/Sale";
import AboutUs from "./section/AboutUs";
import ContactUs from "./section/ContackUs";
import Problems from "./section/Poblems";
import Solutions from "./section/Solutions";
import Buy from "./section/Buy";
import RoadMap from "./section/Roadmap";
import Team from "./section/Team";
import Partners from "./section/Partners";
import FAQ from "./section/FAQ";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import "./home.css";
import Features from "./section/Features";
import Supply from "./section/supply";
import Guide from "./section/Guide";
import Podcast from "./section/Podcast";
import Packages from "./section/Packages";
import DocumentMeta from "react-document-meta";


export default function Home() {

  const meta = {
    title: "Reap Profits with Banana Farms Land Investment 2024",
    description: "Lease up to 700 acres of fractional farmland with Cloudfarms and enjoy future cash flows through Banana 2024 (Autumn) Contracts.",
  };

  return (
    <>
    <DocumentMeta {...meta}>

      <Header />
      <GifSlider />
      <Features/>
      <Sale />
      <Packages />
      <Problems />
      <Solutions />
      <Buy />
      {/* <Guide /> */}
      {/* <Supply /> */}
      <Podcast />
      <RoadMap />
      {/* <Team /> */}
      {/* <Partners /> */}
      <AboutUs />
      {/* <ContactUs /> */}
      <FAQ />
      <Footer />
    </DocumentMeta>
    </>
  );
}

