import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { useEffect } from "react";
import { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormErrorAlert, FormSuccessAlert } from "../../../utils/alert";
import { transferCFC, getBalanceAgainstNftID } from "../../../utils/Blockchain";
import { isEmpty } from "lodash";
import axios from "axios";
import { SyncLoader, PropagateLoader } from "react-spinners";
import { connect } from "react-redux";
import { Loader } from "../../../utils/alert";


function WithdrawModal(props) {

  const [nftID, setNftID] = useState("")
  const [receiverAddress, setReceiverAddress] = useState("")
  const [amount, setAmount] = useState(0)
  const [errorMessage, setErrorMessage] = useState("")
  const [isError, setIsError] = useState(false)
  const [disable, setDisable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [suceess, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")


  let transferCopy = async (e) => {
    e.preventDefault()

    if (isEmpty(nftID)) {
      setIsError(true)
      setErrorMessage("Please provide the ID as it is required.")
      return
    }

    if (isEmpty(receiverAddress)) {
      setIsError(true)
      setErrorMessage("Please provide the receiver's address as it is required.")
      return
    }
    if (parseInt(amount) <= 0) {
      setIsError(true)
      setErrorMessage("The BNNA sending amount must exceed zero.")
      return
    }

    if (parseFloat(props?.dashboard?.currencyBalance) <= 0.01) {
      setIsError(true)
      setErrorMessage("To complete a transaction, you must have a minimum balance of 0.01 ETH in your account");
      return;
    }

    try {

      setIsError(false)
      setErrorMessage("")
      setLoading(true)
      setDisable(true)

      let nftBalance = await getBalanceAgainstNftID(props.wallet.provider, props.wallet.connectedWallet, nftID)
      console.log("nftBalance", nftBalance.toString())



      if (parseFloat(nftBalance) < amount) {
        setIsError(true)
        setErrorMessage(`Your NFT balance is ${nftBalance.toString()} for the provided ID in the text field, and you are attempting to send ${amount} BNNA. Unfortunately, your transaction cannot be processed because you do not have sufficient BNNA to complete it.`)
        setLoading(false)
        setDisable(false)
        return
      }

      await transferCFC(props.wallet.provider, props.wallet.connectedWallet, receiverAddress, nftID, amount)

      setLoading(false)
      setDisable(false)
      setSuccess(true)
      setNftID("")
      setReceiverAddress("")
      setAmount(0)
      setSuccessMessage("Congratulations, the transfer of your BNNA has been completed successfully!")





    }
    catch (e) {
      setLoading(false)
      setDisable(false)
      let msg = e.message ? e.message : "We are facing some issues when trying to transfer BNNA."
      setIsError(true)
      setErrorMessage(msg)

    }








   // alert("transfer")
  }

  let resetState = () => {
    setIsError(false)
    setLoading(false)
    setDisable(false)
    setErrorMessage("")
    setSuccess(false)
    setSuccessMessage("")
    setNftID(0)
    setReceiverAddress("")
    setAmount(0)

  }


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Transfer BNNA
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <form>
              <div class="form-group mb-3">
                <label for="formGroupExampleInput" className="mb-2">
                  Enter BNNA ID
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="formGroupExampleInput"
                  placeholder="Please provide your BNNA ID."
                  value={nftID}
                  onChange={(e) => setNftID(e.target.value)}
                />
              </div>
              <div class="form-group mb-3">
                <label for="formGroupExampleInput2" className="mb-2">
                  Reciever Address
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="formGroupExampleInput2"
                  placeholder="Receiver address"
                  name="wallet"
                  value={receiverAddress}
                  onChange={(e) => setReceiverAddress(e.target.value)}
                />
              </div>
              <div class="form-group mb-3">
                <label for="formGroupExampleInput2" className="mb-2">
                  Enter The Amount Of BNNA You Want To Transfer.
                </label>
                <input
                  type="number"
                  class="form-control"
                  id="formGroupExampleInput2"
                  placeholder="Enter amount of BNNA"
                  name="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>

              <div className="col-12 mb-3">
                {isError && <FormErrorAlert message={errorMessage} />}
              </div>

              <div className="col-12 mb-3">
                {suceess && <FormSuccessAlert message={successMessage} />}
              </div>

              {/* <button
                style={{ backgroundColor: "#019348", border: "none" }}
                className="btn w-100 py-2 btn-primary"
              >
                Send Transaction
              </button> */}
              <div className="col-12 mb-3">
                <button onClick={transferCopy}
                  disabled={disable}
                  style={{ backgroundColor: "#019348", border: "none" }}
                  className="btn w-100 py-2 btn-primary"
                >
                  {loading === true ? <Loader color="#36d7b7"></Loader> : "Transfer BNNA"}

                </button>
              </div>

            </form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={disable}
          style={{ backgroundColor: "#019348", border: "none" }}
          onClick={() => {
            resetState()
            props.onHide()
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => ({

  wallet: state.WalletReducers,
  dashboard: state.DashboardReducers
})

export default connect(mapStateToProps, {})(WithdrawModal)
