import { ethers } from "ethers";
import { constant } from "../config/config";




export const connectMetamask = async () => {
  try {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    const networkId = await window.ethereum.request({ method: 'net_version' });
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    return { provider, accounts, networkId };
  }
  catch (e) {
    let msg = e.message ? e.message : "We are currently facing issues when attempting to connect to Metamask."
    throw new Error(msg);
  }
};


export const switchNetwork = async () => {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: constant.METAMASK_NETWORK_HEXCODE }],    // chainId must be in HEX with 0x in front
    });

  }
  catch (e) {

    if (e.message === `Unrecognized chain ID "${constant.METAMASK_NETWORK_HEXCODE}". Try adding the chain using wallet_addEthereumChain first.`) {
      //attachNetowrk() // will call if client need this
      throw new Error(`${constant.METAMASK_NETOWRK_NAME} is not configured in your MetaMask. Please add it to access and utilize the system`);
    }
    let msg = e.message ? e.message : `Please connect to the ${constant.METAMASK_NETOWRK_NAME} in order to access and utilize the system.`

    throw new Error(msg);
  }
}



export const getInitialInfo = async (provider) => {
  try {
    console.log("my data", constant.cloudFormContractAddress, constant.cloudFormABI, provider);

    const launchPadContract = new ethers.Contract(
      constant.cloudFormContractAddress,
      constant.cloudFormABI,
      provider
    );

    let data = await launchPadContract.getInitailizeInfo();
    console.log("my data response", data, parseInt(data))
    // Handle the data or return it as needed
    return data;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle the error or rethrow it as needed
    throw error;
  }
}


export const getBNBBalance = async (walletAddress, provider) => {
  try {
    const balance = await provider.getBalance(walletAddress);
    const formateBalance = ethers.utils.formatEther(balance);
    return { formateBalance, balance };
  }
  catch (error) {
    console.error("An error occurred:", error);
    // Handle the error or rethrow it as needed
    throw error;
  }
};


export const getUSDTBalance = async (
  provider,
  walletAddress
) => {
  const tokenContract = new ethers.Contract(constant.usdtAddress, constant.erc20ABI, provider);
  const balance = await tokenContract.balanceOf(walletAddress);
  const balanceToken = balance.toString(); //ethers.utils.formatUnits(balance.toString(), 6);
  console.log("balanceToken", balanceToken, constant.usdtAddress, constant.erc20ABI)

  return { balanceToken }
};



export const transactionApprove = async (value, provider) => {
  console.log("Approve", value, typeof value, constant.usdtDecimel)
  try {


    const signer = provider.getSigner();
    console.log("Approve 1")
    const usdtContract = new ethers.Contract(constant.usdtAddress, constant.erc20ABI, provider);
    console.log("Approve 2")

    const daiWithSigner = usdtContract.connect(signer);
    console.log("Approve 3")

    const usdt = ethers.utils.parseUnits(value, 6);
    console.log("Approve 4")

    let transaction = await daiWithSigner.approve(
      constant.cloudFormContractAddress,
      usdt.toString()
    );
    console.log("Approve Transaction", transaction)

    let recipt = await transaction.wait();
    console.log("transaction.hash", recipt)

    return recipt;
  }
  catch (error) {

    console.error("An error occurredddddd:", error);
    // Handle the error or rethrow it as needed
    throw error;
  }
};



export const buyCFC = async (provider, numberOfCopy, referralAddress) => {
  try {
    const cloudFormContract = new ethers.Contract(
      constant.cloudFormContractAddress,
      constant.cloudFormABI,
      provider
    );
    const signer = provider.getSigner();
    const cloudFormWithSigner = cloudFormContract.connect(signer);

    let hash = await cloudFormWithSigner.buy(numberOfCopy, referralAddress);
    console.log("hash", hash)
    return true

  }
  catch (error) {
    console.error("An error:", error);
    // Handle the error or rethrow it as needed
    throw error;
  }
};


export const getBuyInfo = async (provider, userAddress) => {
  //alert("i am reached there")
  try {
    const cloudFormContract = new ethers.Contract(
      constant.cloudFormContractAddress,
      constant.cloudFormABI,
      provider
    );
    const data = await cloudFormContract.getBuyInfo(userAddress);
    console.log("data abc", data)
    return data

  }
  catch (error) {
    console.error("An error:", error);
    // Handle the error or rethrow it as needed
    throw error;
  }
};
export const getTime = async (provider) => {
  //alert("i am reached there")
  try {
    const cloudFormContract = new ethers.Contract(
      constant.cloudFormContractAddress,
      constant.cloudFormABI,
      provider
    );
    const data = await cloudFormContract.getTime();
    console.log("data abc", data)
    return data

  }
  catch (error) {
    console.error("An error:", error);
    // Handle the error or rethrow it as needed
    throw error;
  }
};



export const getBalanceAgainstNftID = async (provider, address, nftID) => {
  console.log("An error: ID", nftID)
  //alert("i am reached there")
  try {
    const cloudFormContract = new ethers.Contract(
      constant.cloudFormContractAddress,
      constant.cloudFormABI,
      provider
    );
    console.log("An error: IDSSS", nftID)
    const data = await cloudFormContract.balanceOf(address, nftID);
    console.log("data rhean", data)

    return data

  }
  catch (error) {
    console.error("An error:", error);
    // Handle the error or rethrow it as needed
    throw error;
  }
};




export const transferCFC = async (provider, sender, receiver, nftID, amount) => {
  console.log("sender, receiver,nftID,amount", sender, receiver, nftID, amount)
  try {
    const cloudFormContract = new ethers.Contract(
      constant.cloudFormContractAddress,
      constant.cloudFormABI,
      provider
    );
    const signer = provider.getSigner();
    const cloudFormWithSigner = cloudFormContract.connect(signer);

    await cloudFormWithSigner.safeTransferFrom(sender, receiver, nftID, amount, "0x");
    return true

  }
  catch (error) {
    console.error("An error:", error);
    // Handle the error or rethrow it as needed
    throw error;
  }
};








export const getNftBalances = async (provider, addressArray, idsArray) => {
  //alert("i am reached there")
  try {
    const cloudFormContract = new ethers.Contract(
      constant.cloudFormContractAddress,
      constant.cloudFormABI,
      provider
    );
    const data = await cloudFormContract.balanceOfBatch(addressArray, idsArray);
    console.log("data abc", data)
    return data

  }
  catch (error) {
    console.error("An error:", error);
    // Handle the error or rethrow it as needed
    throw error;
  }
};



export const getReward = async (provider, address) => {
  //alert("i am reached there")
  try {
    const cloudFormContract = new ethers.Contract(
      constant.cloudFormContractAddress,
      constant.cloudFormABI,
      provider
    );
    const data = await cloudFormContract.getReward(address);
    console.log("data abc", data)
    return data

  }
  catch (error) {
    console.error("An error:", error);
    // Handle the error or rethrow it as needed
    throw error;
  }
};

export const getChildAddress = async (provider, address) => {
  //alert("i am reached there")
  try {
    const cloudFormContract = new ethers.Contract(
      constant.cloudFormContractAddress,
      constant.cloudFormABI,
      provider
    );
    const data = await cloudFormContract.getChildAddress(address);
    console.log("data abc", data)
    return data

  }
  catch (error) {
    console.error("An error:", error);
    // Handle the error or rethrow it as needed
    throw error;
  }
};




export const getRewardInfo = async (provider, address) => {
  //alert("i am reached there")
  try {
    const cloudFormContract = new ethers.Contract(
      constant.cloudFormContractAddress,
      constant.cloudFormABI,
      provider
    );
    const data = await cloudFormContract.getInfoReward(address);
    console.log("data abc", data, data.length)
    return data

  }
  catch (error) {
    console.error("An error:", error);
    // Handle the error or rethrow it as needed
    throw error;
  }
};










export const connectMetamask1 = async () => {

};