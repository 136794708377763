import React from "react";

const Features = () => {
  return (
    <section className="pt-5 pb-5 ">
      <div className="container">
        <h1 className="blackheadingtwo text-center pt-5 pb-5"> Cloud Farms - Platform Features</h1>
        <div className="row justify-content-center">
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="features-cards">
            <div className="pt-4 pb-5">
                <img width={100} src="/assets/images/cloud-farm-icon10W.png" alt="" />
              </div>
              <h2>Digital Convenience</h2>
              <p>Seamless access to portfolio with one-click</p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="features-cards">
            <div className="pt-4 pb-5">
                <img width={100} src="/assets/images/cloud-farm-icon09W.png" alt="" />
              </div>
              <h2>Interoperability</h2>
              <p>Cloudfarms compatibility across agriculture & blockchains</p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="features-cards">
            <div className="pt-4 pb-5">
                <img width={100} src="/assets/images/cloud-farm-icon08W.png" alt="" />
              </div>
              <h2>Liquidity Aggregator</h2>
              <p>Liquidity options are powered on the Ethereum Blockchain adding security amd ability to exchange.</p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="features-cards">
            <div className="pt-4 pb-5">
                <img width={100} height={100} src="/assets/images/cloud-farm-icon07W.png" alt="" />
              </div>
              <h2>Profit Yielding Investment</h2>
              <p>earn Profit as a possible income</p>
            </div>
          </div>
    
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="features-cards">
            <div className="pt-4 pb-5">
                <img width={100} src="/assets/images/cloud-farm-icon02W.png" alt="" />
              </div>
              <h2>Lending & Borrowing</h2>
              <p>Leveraging real estate assets for additional financing opportunities</p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="features-cards">
              <div className="pt-4 pb-5">
                <img width={100} src="/assets/images/cloud-farm-icon03W.png" alt="" />
              </div>
              <h2>Global Accessibility</h2>
              <p>Access a Wider range of farms & earn in us dollars</p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="features-cards">
            <div className="pt-4 pb-5">
                <img width={100} src="/assets/images/cloud-farm-icon05W.png" alt="" />
              </div>
              <h2>Portfolio Diversification</h2>
              <p>Agriculture in a cash-generating business & allows hedging against rising costs of food</p>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="features-cards">
            <div className="pt-4 pb-5">
                <img width={100} src="/assets/images/cloud-farm-icon06W.png" alt="" />
              </div>
              <h2>Fractionalized Assets </h2>
              <p>Distributing ownership enables economies of scale and allows for greater public participation</p>
            </div>
          </div>
        </div>
 
        </div>
    </section>
  );
};

export default Features;
