import Header from "../layout/Header";
import Footer from "../layout/Footer";
import "../home/home.css";
import Guide from "../home/section/Guide";
import Podcast from "../home/section/Podcast";
import GifSlider from "../home/section/GifSlider";

export default function PodcastPage() {
  return (
    <>
      <div className="pod-header">
        <Header />
      </div>
      <Guide />
      <Podcast />
      <Footer />
    </>
  );
}
