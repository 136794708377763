import React from "react";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { metamaskExistAndConnected } from "../../utils/util"
import { getUSDTBalance, getBNBBalance, getInitialInfo, getReward } from "../../utils/Blockchain";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { constant } from "../../config/config";
import store from "../../store";
import { Loader } from "../../utils/alert";
import { LoadingSpinner } from "../../utils/alert";
import { setOneCopyPriceStore, setUsdtBalanceStore, setCurrencyBalanceStore } from "../../redux/action/DashBoardAction";

const Topbar = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [currencyBalance, setCurrencyBalance] = useState(0);
  const [reward, setReward] = useState(0);





  useEffect(() => {
    metamaskExistAndConnected()
  }, [])


  useEffect(() => {
    console.log("inside abc", props.wallet.metamaskConnected)
    if (!isEmpty(props.wallet.metamaskConnected)) {


    }
    if (props.wallet.metamaskConnected === false) {
      window.location.href = constant.FRONTEND_URL;

    }



  }, [props.wallet.metamaskConnected])




  useEffect(() => {

    if (window.ethereum) {



      window.ethereum.on("chainChanged", newNetworkId => {
        console.log("inside window netowrk", window.ethereum, newNetworkId)
        if (newNetworkId !== constant.METAMASK_NETWORK_HEXCODE) {
          window.location.href = constant.FRONTEND_URL;
        }
        else {
          window.location.reload();
        }


      });

      window.ethereum.on("accountsChanged", accounts => {
        console.log("inside window account", window.ethereum)
        if (accounts.length === 0) {
          // MetaMask wallet is likely locked
          window.location.href = constant.FRONTEND_URL;
        } else {

          window.location.reload();
        }


      });
    }


  }, []);




  useEffect(() => {


    if (props.metamaskConnected) {

      let getBalances = async () => {

        try {
          setIsLoading(true)
          let balance = await getBNBBalance(props.wallet.connectedWallet, props.wallet.provider)
          let usdtBalance = await getUSDTBalance(props.wallet.provider, props.wallet.connectedWallet)
          let getSingleCopyPrice = await getInitialInfo(props.wallet.provider);
          let reward = await getReward(props.wallet.provider, props.wallet.connectedWallet)

          setReward(reward.toString() / constant.decimel)


          // price of copy
          let priceOf160Copy = parseInt(getSingleCopyPrice[0]) / constant.decimel
          let priceOfOneCopy = priceOf160Copy / 160
          setUsdtBalance(parseFloat(usdtBalance.balanceToken) / constant.usdtDecimel)
          setCurrencyBalance(balance.formateBalance)

          store.dispatch(setUsdtBalanceStore(parseFloat(usdtBalance.balanceToken) / constant.usdtDecimel))
          store.dispatch(setCurrencyBalanceStore(balance.formateBalance))
          store.dispatch(setOneCopyPriceStore(priceOfOneCopy))

          setIsLoading(false)
        }
        catch (e) {
          console.log("Error", e)
          setIsLoading(false)

        }
      }
      getBalances()

    }

  }, [props.metamaskConnected])




















  return (
    <div>
      <div class="topbar">
        {/* <ul className="">
          <li>
            <a href="">Why Choose Coin</a>
          </li>
          <li>
            <a href="">Our Team</a>
          </li>
          <li>
            <a href="">Estate Upcare coin</a>
          </li>
          <li>
            <a href="">Coin Sale Stage</a>
          </li>
        </ul> */}
        <ul className="TopBarItem">
          <li>
            <a href="">USDT Balance = {isLoading === true ? <LoadingSpinner size={8} color="black" /> : usdtBalance}</a>
          </li>
          <li>
            <a href="">MATIC Balance = {isLoading === true ? <LoadingSpinner size={8} color="black" /> : parseFloat(currencyBalance).toFixed(3)}</a>
          </li>
          <li>
          <a href="">Total Reward = {isLoading === true ? <LoadingSpinner size={8} color="black" /> : reward+" "+"USDT"}</a>
           
          </li>
        </ul>
        <div className="button-sec ms-auto">
          <div>
            <a href="/" className="buy-b">
              Logout
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};





const mapStateToProps = (state) => ({

  wallet: state.WalletReducers,
  metamaskConnected: state.WalletReducers.metamaskConnected
})

export default connect(mapStateToProps, {})(Topbar)
