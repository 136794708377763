import languageStrings from "../../../language/index";

export default function Problems() {
  return (
    <>
      <section className="Problems">
        <div className="container">
          <div className="row pb-3">
            <div className="col-lg-12 col-dash-12 text-center">
              <div>
                <h4 className="blackheadingtwo mb-2">
                  {languageStrings["Problems"]}
                </h4>
                <p className="description">
                  {
                    languageStrings[
                      "One of the significant challenges faced by individuals worldwide is limited access to financial services and profitable investment opportunities."
                    ]
                  }
                </p>
              </div>
            </div>
          </div>
          <div
            className="row align-items-center"
            style={{ marginBottom: "80px" }}
          >
            <div className="col-lg-6">
              <div>
                <div className="content mb-5">
                  <div className="ListCount mb-4">
                    <h3 className="List">01</h3>
                  </div>
                  <h4 className="brownheadingtwop mb-3">
                    Geographical Limitations
                  </h4>
                  <p className="description mb-5">
                    Geographical limitations pose challenges for investors to
                    venture into real estate in a foreign country reliably. For
                    example, an investor from Europe cannot invest in tropical
                    crops of ciently and those from warmer countries cannot farm
                    in cold climate regions. We identify and acquire the most
                    suitable land and partner up with stakeholders like farmers,
                    land owners, farm operators and the markets.
                  </p>
                  {/* <a href="#" className="btn btn-custom">
                    {languageStrings['Learn More']}
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <div className="banner ">
                  <img
                    src="/assets/images/geogrpahical.png"
                    alt=""
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="row align-items-center"
            style={{ marginBottom: "80px" }}
          >
            <div className="col-lg-6">
              <div>
                <div className="banner mb-5">
                  <img
                    src="/assets/images/Accessibility.png"
                    alt=""
                    className="w-100"
                    style={{ marginTop: "30px" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <div className="content">
                  <div className="ListCount mb-4">
                    <h3 className="List">02</h3>
                  </div>
                  <h4 className="brownheadingtwop mb-3">
                    Choosing a profitable crop knowledge of the Supply Chain
                  </h4>
                  <p className="description mb-5">
                    Some fruits are native to their regions and consultants at
                    CloudFarms assess the viability of farms around the world.
                    By grading the available options, we can choose an
                    opportunity with a favorable risk to reward ratio and for
                    their ability to generate cash ows.
                  </p>
                  {/* <a href="#" className="btn btn-custom">
                    {languageStrings['Learn More']}
                  </a> */}
                </div>
              </div>
            </div>
          </div>

          <div
            className="row align-items-center"
            style={{ marginBottom: "80px" }}
          >
            <div className="col-lg-6">
              <div>
                <div className="content mb-5">
                  <div className="ListCount mb-4">
                    <h3 className="List">03</h3>
                  </div>
                  <h4 className="brownheadingtwop mb-3">
                    Investor’s safety and flexibility
                  </h4>
                  <p className="description mb-5">
                    Traditional REITs (Real Estate Investment Trusts) are often
                    in a complicated business structure and operate in leverage
                    markets. At CloudFarms, we have simpli ed land ownership
                    into a secure digital asset. The advantages over REIT are
                    that they are an easy, low-cost vehicle to represent your
                    investment security. The ownership of the large farm can be
                    divided into smaller denominations of land making them easy
                    to identify and make accessible for more people. They’re
                    also tradable in the aftermarket, with option to sell in the
                    aftermarket. Our investment opportunities are cash flow
                    generating meaning your acre has a tree or a crop which
                    bears a fruit or a harvest, paying you periodically. This
                    REIT enables you to de ne your wealth in future food
                    produce.
                  </p>
                  {/* <a href="#" className="btn btn-custom">
                    {languageStrings['Learn More']}
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div>
                <div className="banner ">
                  <img
                    src="/assets/images/finance.png"
                    alt=""
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
