import Home from "./component/home/Home";
import PodcastPage from "./component/Podcast/PodcastPage";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Dashboard from "./component/dashboard/Dashboard";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import History from "./component/dashboard/History";
import Referal from "./component/dashboard/Referal";
import Topbar from "./component/dashboard/Topbar";
import Sidebar from "./component/dashboard/Sidebar";
import Mobilemenu from "./component/dashboard/Mobilemenu";
import TrackFarm from "./component/dashboard/TrackFarm";

function App() {
  //const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(true);
  useEffect(() => {
    AOS.init();
  }, []);
  const [isMobileMenuVisible, setisMobileMenuVisible] = useState(true);
  const handleResize = () => {
    setisMobileMenuVisible(window.innerWidth <= 991);
  };
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/podcast" component={PodcastPage} />

          <div id="dashboard-div">
            <Sidebar />
            {isMobileMenuVisible && <Mobilemenu />}

            <div className="main">
              <Topbar />

              <Route
                // path="/dashboard/:id(purchase-token|payment|transaction|home|transaction-history)"
                exact
                path="/home"
                component={Dashboard}
              />
              <Route
                // path="/dashboard/:id(purchase-token|payment|transaction|home|transaction-history)"
                exact
                path="/transaction-history"
                component={History}
              />
              <Route
                // path="/dashboard/:id(purchase-token|payment|transaction|home|transaction-history)"
                exact
                path="/referal"
                component={Referal}
              />
              <Route
                // path="/dashboard/:id(purchase-token|payment|transaction|home|transaction-history)"
                exact
                path="/trackfarm"
                component={TrackFarm}
              />

              {/* {path === "transaction-history" && <History />}
        {path === "referal" && <Referal />} */}
            </div>
          </div>
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
