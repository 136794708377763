import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { constant } from "../../../config/config";
import { connect } from "react-redux";

import {
  metamaskConnectionProcess,
  metamaskExistAndConnected,
} from "../../../utils/util";

function WalletConnect(props) {
    const history = useHistory();

  useEffect(() => {
    metamaskExistAndConnected();
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", (newNetworkId) => {
        window.location.reload();
      });
      window.ethereum.on("accountsChanged", (accounts) => {
        window.location.reload();
      });
    }
  }, []);

  let connect = async () => {
    await metamaskConnectionProcess();
  };
  return (
    <>
      <a
        onClick={() => {
          if (
            props?.wallet?.metamaskConnected === false ||
            props?.wallet?.networkID !== constant.NETWORK_ID
          ) {
            connect();
          } else if (
            props?.wallet?.metamaskConnected === true ||
            props?.wallet?.networkID === constant.NETWORK_ID
          ) {
            history.push("/home");
          }
        }}
        // href=""
        //target="_blank"
        className="btn btn-custom px-4 rounded-pill"
      >
        {props?.wallet?.metamaskConnected === true &&
        props?.wallet?.networkID === constant.NETWORK_ID
          ? "Dashboard"
          : "Connect Wallet"}
      </a>
    </>
  );
};

// export default WalletConnect;

const mapStateToProps = (state) => ({
  wallet: state.WalletReducers,
});

export default connect(mapStateToProps, {})(WalletConnect);
