export const PROVIDER = 'PROVIDER'
export const SET_NETOWRK_ID = 'SET_NETOWRK_ID'
export const SET_WALLET = "SET_WALLET"
export const METAMASK_PROVIDER = 'METAMASK_PROVIDER'
export const METAMASK_CONNECTION = 'METAMASK_CONNECTION'
export const USER_WALLET_USDT_BALANCE = "USER_WALLET_USDT_BALANCE"
export const USER_WALLET_BNB_BALANCE = "USER_WALLET_BNB_BALANCE"
export const CURRENT_TAB = "CURRENT_TAB"
export const SET_USDT_BALANCE = "SET_USDT_BALANCE"
export const SET_CURRENCY_BALANCE = "SET_CURRENCY_BALANCE"
export const SET_ONE_COPY_PRICE = "CURRENT_TAB"
export const SET_NFT_BALANCE = "SET_NFT_BALANCE"
export const SET_LOADING = "SET_LOADING"




