import react, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { connectMetamask1 } from "../../../utils/Blockchain";
import { constant } from "../../../config/config";
// import Countdown from "react-countdown";
import Spinner from "react-bootstrap/Spinner";

import {
  switchNetwork,
  connectMetamask,
  getInitialInfo,
  getBNBBalance,
  getUSDTBalance,
  getTime,
} from "../../../utils/Blockchain";
import { yesOrNoModalAlert, modalAlert } from "../../../utils/alert";
import {
  setNetworkID,
  setWallet,
  saveProvider,
  setMetamaskConnection,
} from "../../../redux/action/WalletAction";
import store from "../../../store";
import { metamaskConnectionProcess } from "../../../utils/util";
import { FormErrorAlert } from "../../../utils/alert";
import { isEmpty } from "lodash";
import { buyCFC, transactionApprove } from "../../../utils/Blockchain";
import { Loader } from "../../../utils/alert";
import SignUpModal from "../../dashboard/Modal/SignUpModal";

import Countdown from "./Countdown";
import { truckMonster } from "fontawesome";

function Sale(props) {
  const [numbofCopy, setNumbOfCopy] = useState("");
  const [referral, setReferral] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suceess, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [privateSaleEndTimes, setPrivateSaleEndTimes] = useState("");
  const [modalShowSign, setModalShowSign] = useState(false);


  // Balances
  const [usdtBalance, setUsdtBalance] = useState("");
  const [currencyBalance, setCurrencyBalance] = useState("");
  const [priceOfOneCFC, setPriceOfOneCFC] = useState(0);
  const [usdtPayable, setUsdtPayable] = useState(0);

  // const Completionist = () => <span>You are good to go!</span>;

  // const renderer = ({ days, hours, minutes, seconds, completed }) => {
  //   const formatTime = (value) => {
  //     return value < 10 ? `0${value}` : value;
  //   };

  //   if (completed) {
  //     return <Completionist />;
  //   } else {
  //     return (
  //       <div className="d-flex mt-4 countdown justify-content-center">
  //         <div className="p-2">
  //           <div className="d-flex flex-column ">

  //           <h2 className="time-num">
  //             {loading ? (
  //               <Spinner animation="grow" />
  //             ) : (
  //               formatTime(Number.isNaN(days) ? 0 : days)
  //             )}{" "}
  //             :
  //           </h2>
  //           <h4>days</h4>
  //             </div>
  //         </div>
  //         <div className="p-2">
  //         <div className="d-flex flex-column ">

  //           <h2 className="time-num">
  //             {loading ? (
  //               <Spinner animation="grow" />
  //             ) : (
  //               formatTime(Number.isNaN(hours) ? 0 : hours)
  //             )}{" "}
  //             :
  //           </h2>
  //           <h4>hrs</h4>
  //             </div>
  //         </div>
  //         <div className="p-2">
  //         <div className="d-flex flex-column ">

  //           <h2 className="time-num">
  //             {loading ? (
  //               <Spinner animation="grow" />
  //             ) : (
  //               formatTime(Number.isNaN(minutes) ? 0 : minutes)
  //             )}{" "}
  //             :
  //           </h2>
  //           <h4>min</h4>
  //           </div>
  //         </div>
  //         <div className="p-2">
  //         <div className="d-flex flex-column ">
  //           <h2 className="time-num">
  //             {loading ? (
  //               <Spinner animation="grow" />
  //             ) : (
  //               formatTime(Number.isNaN(seconds) ? 0 : seconds)
  //             )}
  //           </h2>
  //           <h4>sec</h4>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   }
  // };
  useEffect(() => {
    // alert(numbofCopy)
    if (isNaN(parseInt(numbofCopy))) {
      setUsdtPayable(0);
    } else {
      setUsdtPayable(priceOfOneCFC * parseInt(numbofCopy));
    }

    // if (parseInt(numbofCopy) > 0
    //   // && parseInt(numbofCopy) % 40 === 0

    // )
    //   console.log("pro", priceOfOneCFC)
  }, [numbofCopy]);

  useEffect(() => {
    let getBalances = async () => {
      try {
        let usdtBalance1 = await getUSDTBalance(
          props.wallet.provider,
          props.wallet.connectedWallet
        );
        let balance = await getBNBBalance(
          props.wallet.connectedWallet,
          props.wallet.provider
        );
        console.log(props.wallet, "props");
        let getSingleCFCPrice = await getInitialInfo(props.wallet.provider);
        let priceOf160CFC = parseInt(getSingleCFCPrice[0]) / constant.decimel;
        let priceOfOneCFC = priceOf160CFC / 160;

        const time = await getTime(props.wallet.provider);

        const privateSaleEndDate = new Date("2024-09-30T00:00:00Z");
        const unixTimestamp = Math.floor(privateSaleEndDate.getTime() / 1000);
        setPrivateSaleEndTimes(Number(unixTimestamp));
        // setPrivateSaleEndTimes(Number(time[1]._hex));

        setPriceOfOneCFC(priceOfOneCFC);
        // console.log("usdtBalance", usdtBalance.balanceToken, balance.formateBalance)
        setCurrencyBalance(balance.formateBalance);
        setUsdtBalance(usdtBalance1.balanceToken / constant.decimel);
        console.log("privateSaleEndTimes", privateSaleEndTimes);
      } catch (e) {}
    };
    getBalances();
  });

  let Purchase = async () => {
    try {
      if (props.wallet.networkID !== constant.NETWORK_ID) {
        await metamaskConnectionProcess();
      }
      if (parseInt(numbofCopy) <= 0) {
        setIsError(true);
        setErrorMessage("The BNNA amount must exceed zero.");
        return;
      }

      if (parseFloat(currencyBalance) <= 0.01) {
        setIsError(true);
        setErrorMessage(
          "To complete a transaction, you must have a minimum balance of 0.01 Polygon in your account"
        );
        return;
      }

      if (
        parseFloat(usdtBalance) <=
        parseFloat(numbofCopy) * parseFloat(priceOfOneCFC)
      ) {
        setIsError(true);
        setErrorMessage(
          "You must top up your balance before proceeding with the transaction, as your current balance is low"
        );
        return;
      }

      if (
        referral.toLowerCase() === props.wallet.connectedWallet.toLowerCase()
      ) {
        setIsError(true);
        setErrorMessage(
          "Select a distinct referral wallet; self-referrals are not permitted."
        );
        return;
      }

      let referallAddress = isEmpty(referral)
        ? constant.MARKETING_REFERAL_ADDRESS
        : referral;


        // setModalShowSign(true)

      setIsError(false);
      setErrorMessage("");
      setLoading(true);
      setDisable(true);

      await transactionApprove(
        (parseFloat(numbofCopy) * parseFloat(priceOfOneCFC)).toString(),
        props.wallet.provider
      );
      await buyCFC(props.wallet.provider, numbofCopy, referallAddress);
      setLoading(false);
      setDisable(false);
      setSuccess(true);
      setNumbOfCopy(0);
      setReferral("");
      setUsdtPayable(0);
      modalAlert(
        "",
        "Congratulations, your purchase of the BNNA has been successful!",
        "success"
      );
    } catch (e) {
      setLoading(false);
      setDisable(false);
      let msg = e.message
        ? e.message
        : "We are facing some issues when trying to buy BNNA.";
      modalAlert("", msg, "error");
    }
  };

  return (
    <>

<SignUpModal
        show={modalShowSign}
        onHide={() => setModalShowSign(false)}
      />
      <section
        id="sale"
        className="Sale d-flex align-items-center position-relative"
      >
        <div className="hotsalebg">
          <div className="container ">
            <div className="">
              <div className="row align-items-center">
                <div className="col-lg-7 col-dash-7">
                  <div>
                    <div
                      className="content "
                      style={{ zIndex: "9999", marginBottom: "40px" }}
                    >
                      <h4 className="brownheading m-0">
                        Ongoing Funding Round at CloudFarms
                      </h4>
                      <h5 className="blackheading mb-2">
                        Banana 2024 (Autumn) Contract
                      </h5>
                      <p className="description m-0">
                        We have identified arable land which we can lease for a
                        year. We will be acquiring the farms on the subscription
                        date up to 700 acres for this year’s contract. By
                        purchasing shares of this contract entities you to all
                        the future cashflows of the farm for the term. Using our
                        app, individuals and corporations can participate and
                        invest in a piece of this farm. YOUR DIGITAL ASSET
                        REPRESENTS a physical acre of Banana Farm 2024 (Autumn).
                        On the platform you can acquire the representations of
                        real land on a digital platform secured by Blockchain.
                        The ownership and the investment is rewarded in Digital
                        Currency, like USD Coin. After the fundraising round,
                        land would be acquired and banana farming begins. Track
                        your farm on the CloudFarms App “Dashboard”. Land
                        contracts and farm operator contracts are made with
                        Cloudfarms holding company. Profits are distributed in
                        US dollars proportionately to your holding and you
                        receive cash straight to your wallet. Our first project
                        on blockchain comes after decades of experience and
                        knowledge in banana farming and selling in the
                        subcontinent. Cloudfarms and its subcontractors manage
                        your farm and track all financial activity - maintaining
                        transparency in the methods. All investments are
                        non-interest bearing, profits of farming and based on
                        prices and quantity of production.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-dash-5">
                  <div>
                    <div className="banner banner-sale text-center">
                      <div className="salecontent">
                        <div className="d-flex align-items-center mb-4 justify-content-center">
                          <span>
                            <img src="/assets/images/fire.png" alt="" />
                          </span>
                          <div className="col">
                            <h4 className="greenheadingtwo m-0">
                              Banana Farm 2024 (Autumn) Sale
                            </h4>
                            <div className="timer">
                              <Countdown targetDate={privateSaleEndTimes} />
                              {/* <Countdown
                              date={new Date(privateSaleEndTimes * 1000)}
                              renderer={(props) => {
                                if (props.completed) {
                                  return (
                                    <div
                                      className="text-center countdown pt-4 pb-4 mt-3 mb-3 font-bold "
                                      style={{ fontWeight: "700" }}
                                    >
                                      {" "}
                                      Your time is over
                                    </div>
                                  );
                                } else {
                                  return renderer(props);
                                }
                              }}
                              /> */}
                            </div>
                          </div>

                          <span>
                            <img src="/assets/images/fire.png" alt="" />
                          </span>
                        </div>
                        {/* <h3 className="m-3">Presale End In</h3> */}
                        {/* <h4 className="mt-3">
                          Annual Expect Profit: 46% in USDT
                        </h4>
                        <h4 className="mt-3">
                          Annual one acre production of banana: 22,400 KG{" "}
                        </h4>
                        <h4 className="mt-3">One acre = 160 BNNA24</h4> */}
                        <h4 className="mt-3">Price: 1 BNNA24 = USDT 5.43</h4>
                        {/* <Countdown
                          date={new Date(Date.now() + 555500000).getTime()}
                          renderer={renderer}
                        /> */}
                        <span
                          style={{ fontSize: "15px", fontWeight: "500" }}
                          className="d-block mb-4 mt-4"
                        >
                          {/* Listing price : $0.1 */}
                        </span>
                        {/* <ul className="d-flex coins justify-content-center list-inline">
                          <li className="eth-list">
                            ETH
                            <span>
                              <img src="/assets/images/et.png" alt="" />
                            </span>
                          </li>
                          <li className="eth-list">
                            USDT
                            <span>
                              <img src="/assets/images/ustd.png" alt="" />
                            </span>
                          </li>
                          <li className="eth-list">
                            BNB
                            <span>
                              <img src="/assets/images/bnb-b.png" alt="" />
                            </span>
                          </li>
                          <li className="eth-list">
                            CARD
                            <span>
                              <img
                                src="/assets/images/credit-card.png"
                                alt=""
                              />
                            </span>
                          </li>
                        </ul> */}

                        <div
                          className="d-flex justify-content-between mb-1"
                          style={{
                            color: "#F9F4CA",
                            fontSize: "12px",
                            margin: "0px 5px 10px 5px",
                            fontWeight: "500",
                          }}
                        >
                          <div>Enter the BNNA amount.</div>
                          <div
                            style={{ fontWeight: "500", fontSize: 14 }}
                          >{`USDT Payable: ${usdtPayable}`}</div>
                        </div>

                        <div
                          className="input-group flex-nowrap"
                          style={{
                            margin: "0px 5px 20px 5px",
                            paddingRight: "10px",
                          }}
                        >
                          <input
                            type="number"
                            className="form-control cus-input"
                            placeholder="Enter the BNNA number"
                            value={numbofCopy}
                            onChange={(e) => setNumbOfCopy(e.target.value)}
                            aria-describedby="addon-wrapping"
                            style={{
                              height: "50px",
                              marginTop: "0px",
                              border: "1px solid #F9F4CA",
                            }}
                          />
                          <span
                            className="input-group-text"
                            style={{
                              padding: "0px 20px",
                              border: "1px solid #F9F4CA",
                              backgroundColor: "#CBA289",
                            }}
                            id="addon-wrapping"
                          >
                            <img src="/assets/images/to.png" alt="" />
                          </span>
                        </div>

                        <div
                          className="d-flex justify-content-between"
                          style={{
                            color: "#F9F4CA",
                            fontSize: "12px",
                            margin: "0px 5px 4px 5px",
                            fontWeight: "500",
                          }}
                        >
                          <div>Enter Referral Address</div>
                        </div>

                        <div
                          className="input-group flex-nowrap"
                          style={{
                            margin: "0px 5px 10px 5px",
                            paddingRight: "10px",
                          }}
                        >
                          <input
                            type="text"
                            className="form-control cus-input"
                            aria-label=""
                            aria-describedby="addon-wrapping"
                            style={{
                              height: "50px",
                              marginTop: "0px",
                              border: "1px solid #F9F4CA",
                            }}
                            value={referral}
                            placeholder="Enter referral address."
                            onChange={(e) => setReferral(e.target.value)}
                          />
                          <span
                            className="input-group-text"
                            style={{
                              padding: "0px 20px",
                              border: "1px solid #F9F4CA",
                              backgroundColor: "#CBA289",
                            }}
                            id="addon-wrapping"
                          >
                            <img src="/assets/images/to.png" alt="" />
                          </span>
                        </div>
                        <span
                          className="d-block mt-4 mb-3"
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            marginLeft: "60px",
                            marginRight: "60px",
                          }}
                        ></span>
                        {isError && <FormErrorAlert message={errorMessage} />}

                        <a
                          onClick={Purchase}
                          className="btn mb-1 btn-custom"
                          style={{
                            backgroundColor: "#CDA991",
                            pointerEvents: disable === true ? "none" : "",
                          }}
                        >
                          {loading === true ? (
                            <Loader color={"#ab7e63"}></Loader>
                          ) : (
                            " Buy BNNA"
                          )}
                        </a>
                        <a
                          id="nav-to-how-to-buy"
                          href="#how-to-buy"
                          style={{ display: "none" }}
                        ></a>
                        {/* <span
                          className="d-block mt-4 mb-1"
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src="/assets/images/help.png"
                            className="me-2"
                            alt=""
                          />
                          How to buy
                        </span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ConnectWallet /> */}
      </section>
    </>
  );
}

const mapStateToProps = (state) => ({
  wallet: state.WalletReducers,
});

export default connect(mapStateToProps, {})(Sale);
