
import React, { useEffect, useState, useRef } from "react";
import { getBuyInfo } from "../../utils/Blockchain";
import { modalAlert } from "../../utils/alert";
import { connect } from "react-redux";
import { isEmpty } from "lodash"
import { BarLoader } from "react-spinners";
import moment from "moment";


const History = (props) => {
  const [loading, setLoading] = useState(true)
  const [result, setResult] = useState([])







  useEffect(() => {
    let getTransactionHistory = async () => {
      try {

        if (!isEmpty(props.wallet.provider)) {

          let nestedArray = await getBuyInfo(props.wallet.provider, props.wallet.connectedWallet);

          const data = nestedArray[0].map((_, i) => {
            return {
              id: nestedArray[0][i].toString(),
              CFC: nestedArray[1][i].toString(),
              timestamp: nestedArray[2][i].toString(),
              address: nestedArray[3][i]
            };
          });

          setResult(data)
          setTimeout(() => {
            setLoading(false)
          }, 2000);

        }

       
      }
      catch (e) {
        setLoading(false)
        let msg = e.message ? e.message : "We are facing some issues to get the history."
        modalAlert('', msg, 'error')

      }
    }
    getTransactionHistory()

    return () => {
      //  alert("i am hit")
      setResult([])
      // This code will run when the component is unmounted.
      // You can perform cleanup tasks here, such as clearing intervals, canceling requests, etc.
    };

  }, [props.wallet])
  return (
    <>
      {/* <div id="dashboard-div">
        <Sidebar />
        {isMobileMenuVisible && <Mobilemenu />}

        <div class="main">
          <Topbar /> */}
          <div className="inner-main">
            <h1 className="main-heading">Transaction History</h1>
            {
              loading === true ? <div style={{ display: "flex" }} > <BarLoader width={"100%"} color="#36d7b7" /></div> : result.length > 0 ?







                <div className="col-lg-12 mt-5">
                  <div class="card">
                    <div class="card-body">
                      {/* <h5 class="card-title m-0">Your referal Ids</h5> */}
                      {/* <p class="card-text mb-3">Details</p> */}
                      {/* <hr /> */}

                      <div className="table-responsive">
                        <table
                          className="table-responsive"
                          style={{ width: "100%", color: "white" }}
                        >
                          <tbody>

                            <tr
                              style={{
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                              }}
                            >
                              <th style={{ padding: "15px" }}>NFT ID</th>
                              <th style={{ padding: "15px" }}>Purchase Amount (BNNA)</th>
                              <th style={{ padding: "15px" }}>Referral Wallet Address</th>
                              <th style={{ padding: "15px" }}>Timestamp</th>
                            </tr>
                            {result.map((item) => (
                              // Use a conditional statement to check if item.CFC is not equal to 0 before rendering the row.
                              parseInt(item.CFC) !== 0 && (
                                <tr key={item.id}> {/* Don't forget to add a unique key for each row */}
                                  <td style={{ padding: "15px" }}>{item.id}</td>
                                  <td style={{ padding: "15px" }}>{item.CFC} BNNA</td>
                                  <td style={{ padding: "15px" }}>{item.address}</td>
                                  <td style={{ padding: "15px" }}>{moment.unix(item.timestamp).fromNow()}</td>
                                </tr>
                              )
                            ))}

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                : null
            }


            {loading === false &&
              result.length === 0 && (
                <div style={{ textAlign: "center" }}>
                  <img
                    style={{ maxWidth: "400px" }}
                    src="/assets/images/no-data-found.avif"
                  />
                  <h4 className="mt-4">
                  There are no transactions currently.
                  </h4>
                </div>
              )}





          </div>

        {/* </div>
      </div> */}

    </>
  );
}


const mapStateToProps = (state) => ({

  wallet: state.WalletReducers,
  dashboard: state.DashboardReducers
})

export default connect(mapStateToProps, {})(History)
