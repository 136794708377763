import { combineReducers } from 'redux';
import { WalletReducers } from './WalletReducers';
import { DashboardReducers } from './DashboardReducers';


const reducer = combineReducers({
    WalletReducers: WalletReducers,
    DashboardReducers:DashboardReducers
   
});

export default reducer;