import React, { useState, useEffect } from 'react';

const Completionist = () => <span>You are good to go!</span>;

const Countdown = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    const calculateTimeLeft = () => {

      const privateSaleEndDate = new Date("2024-09-30T00:00:00Z");
      const unixTimestamp = Math.floor(privateSaleEndDate.getTime() / 1000);

      const now = new Date().getTime();
      const distance = unixTimestamp * 1000 - now;

      if (distance >= 0) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        setIsComplete(true);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  return (
    <div>
      {isComplete ? (
        <Completionist />
      ) : (
        <p className='time-num mt-3'>
          {`${timeLeft.days}d : ${formatTime(timeLeft.hours)}h : ${formatTime(timeLeft.minutes)}m : ${formatTime(timeLeft.seconds)}s`}
        </p>
      )}
    </div>
  );
};

export default Countdown;
