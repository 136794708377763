
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash"
import { getReward, getChildAddress, getRewardInfo } from "../../utils/Blockchain";
import { modalAlert, yesOrNoModalAlert } from "../../utils/alert";
import { BarLoader } from "react-spinners";
import { constant } from "../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";



function Referal(props) {

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {

    let getTransactionHistory = async () => {
      try {

        if (!isEmpty(props.wallet.provider)) {
          let result = []


          let addresses = await getRewardInfo(props.wallet.provider, props.wallet.connectedWallet)
          console.log("addresses", addresses)

          const values = addresses.toString().split(',');
          console.log("addresses split", values[0])
          if (isEmpty(values[0])) {
            setData(result)
          }
          else {


            // Determine the number of addresses and rewards
            const numAddresses = values.length / 2;
            console.log("addresses numAddresses", numAddresses)


            // Initialize an array to store the formatted data


            // Loop through the data and format it
            for (let i = 0; i < numAddresses; i++) {
              const address = values[i];
              const reward = values[i + numAddresses] / constant.decimel;
              // Create an object with the required format and push it to the formattedData array
              result.push({ address, reward });
            }

            setData(result)
          }
          setTimeout(() => {
            setLoading(false)
          }, 2000);


        }
      }
      catch (e) {
        setLoading(false)
        let msg = e.message ? e.message : "We are facing some issues to get the history."
        modalAlert('', msg, 'error')

      }
    }

    getTransactionHistory()
    return () => {
      //  alert("i am hit")
      setData([])
      // This code will run when the component is unmounted.
      // You can perform cleanup tasks here, such as clearing intervals, canceling requests, etc.
    };


  }, [props.wallet])

  const copyToClipboard = () => {
    const textToCopy = props?.wallet?.connectedWallet;

    if (textToCopy) {
      // Create a temporary input element to facilitate copying the text.
      const tempInput = document.createElement('input');
      tempInput.value = textToCopy;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);

      // Optionally, you can provide feedback to the user that the text has been copied.
     // alert('Text copied to clipboard!');
      modalAlert("The referral address has been successfully copied to the clipboard.",  "Copied!", "success")
    }
  };

  return (
    <>
      {console.log("hits", data, loading)}
      {/* <div id="dashboard-div">
        <Sidebar />
        {isMobileMenuVisible && <Mobilemenu />}

        <div class="main">
          <Topbar /> */}
      <div className="inner-main">
        <h1 className="main-heading">Referred History</h1>


        <div className="row">
          <div className="col-lg-12">
            <label className="sub-heading mb-2" htmlFor="">
              Your Referral ID
            </label>
            <div style={{ display: "flex", flexDirection: "row" }} class="card">
              <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }} class="card-body">
                <h5 className="m-0">
                  {props?.wallet?.connectedWallet}
                  <span></span>{" "}
                </h5>
              </div>
              <div style={{ flex: 1.5 }} class="card-body">
                <FontAwesomeIcon onClick={copyToClipboard} style={{ height: 30, cursor: "pointer" }} icon={faCopy} />

              </div>


            </div>
            {/* <button onClick={copyToClipboard}>Click here</button>  */}
          </div>
          {/* <FontAwesomeIcon icon={faCopy} /> */}

          {
            loading === true ? <div style={{ display: "flex", marginTop: 10 }} > <BarLoader width={"100%"} color="#36d7b7" /></div> : data.length > 0 ?



              <div className="col-lg-12 mt-5">
                <div class="card">
                  <div class="card-body">
                    {/* <h5 class="card-title m-0">Your referal Ids</h5> */}
                    <p class="card-text mb-3">Details</p>
                    <hr />

                    <div className="table-responsive">
                      <table
                        className="table-responsive"
                        style={{ width: "100%", color: "white" }}
                      >
                        <tbody>
                          <tr
                            style={{
                              backgroundColor: "#fff",
                              borderRadius: "10px",
                            }}
                          >
                            <th style={{ padding: "15px" }}>Referred Address</th>
                            <th style={{ padding: "15px" }}>Reward</th>

                          </tr>
                          {data.map((item) => (
                            // Use a conditional statement to check if item.CFC is not equal to 0 before rendering the row.
                            (
                              <tr key={item.id}> {/* Don't forget to add a unique key for each row */}
                                <td style={{ padding: "15px" }}>{item.address}</td>
                                <td style={{ padding: "15px" }}>{item.reward} USDT</td>

                              </tr>
                            )
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              : null
          }
        </div>



        {loading === false &&
          data.length === 0 && (
            <div style={{ textAlign: "center" }}>
              <img
                style={{ maxWidth: "400px" }}
                src="/assets/images/no-data-found.avif"
              />
              <h4 className="mt-4">
                At the moment, no one has utilized your address for referrals.
              </h4>
            </div>
          )}





      </div>
      {/* </div>
      </div> */}
    </>
  );
}


const mapStateToProps = (state) => ({

  wallet: state.WalletReducers,

})

export default connect(mapStateToProps, {})(Referal)