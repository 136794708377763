import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { getBuyInfo, getNftBalances } from "../../utils/Blockchain";
import { modalAlert } from "../../utils/alert";
import { BarLoader } from "react-spinners";
import { connect } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
const specialValues = [1, 2, 5];
const TrackFarm = (props) => {
  const [active, setActive] = useState("");
  const [result1, setResult] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [activeId, setActiveId] = useState(null);
 
  const handleBlockClick = (id) => {

    setActiveId(id);
   
  };

  const arr = [];

  for (let i = 0; i < 250; i++) {
    arr.push(i);
  }




  useEffect(() => {

    let getTransactionHistory = async () => {
      try {
        //store.dispatch({ type: SET_LOADING, payload: true })

        if (!isEmpty(props.wallet.provider)) {

          let nestedArray = await getBuyInfo(props.wallet.provider, props.wallet.connectedWallet);
          // Extract unique IDs from the nestedArray
          const uniqueIds = [...new Set(nestedArray[0].map(item => item.toString()))];
          console.log("news", nestedArray, uniqueIds)
          // Create an array of unique IDs as numbers
          const idsArray = uniqueIds.map(id => parseInt(id)); // Assuming you want the IDs as numbers
          // setID(idsArray);
          const addressArray = Array(idsArray.length).fill(props.wallet.connectedWallet);
          // setAddress(addressArray);
          let response = await getNftBalances(props.wallet.provider, addressArray, idsArray);
          const data = idsArray.map((id, index) => ({
            id: id,
            balance: response[index].toString(),
          }));



          setResult(data)
          setTimeout(() => {
            setLoading(false)
          }, 2000);
          // store.dispatch(setNftBalances(result))
          // store.dispatch({ type: SET_LOADING, payload: false })
        }
      }
      catch (e) {
        // store.dispatch({ type: SET_LOADING, payload: false })
        setLoading(false)
        let msg = e.message ? e.message : "We are facing some issues to get the history."
        modalAlert('', msg, 'error')

      }
    }
    getTransactionHistory()

    return () => {
      //  alert("i am hit")
      setResult([])
      // This code will run when the component is unmounted.
      // You can perform cleanup tasks here, such as clearing intervals, canceling requests, etc.
    };
  }, [props.wallet])





  return (
    <div>

      <div className="inner-main track-map">
        {console.log("dddd", result1)}

        <h1 className="main-heading">Track Farm</h1>


        {
          loading === true ? <div style={{ display: "flex", marginTop: 10 }} > <BarLoader width={"100%"} color="#36d7b7" /></div> : result1.length > 0 ?





            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex align-items-center flex-wrap">
                  {arr.map((item) => (
                    <div
                      key={item}
                      style={{
                        background: result1.some((value) => value.id === item) ? "green" : ""
                      }}
                      data-tooltip-id="my-tooltip-1"
                      className={`block-farm ${item === active ? "isblock" : ""}`}
                      onClick={() => handleBlockClick(item)}
                    >
                      <div className="d-flex justify-content-center">{item}</div>
                    </div>
                  ))}
                </div>
              </div>

              {/* <Tooltip place="top" type="dark" effect="float">
        {activeId !== null && (
          <span>Balance: {result1.find((item) => item.id === activeId).balance}</span>
        )}
      </Tooltip> */}


              <ReactTooltip
                id="my-tooltip-1"
                place="bottom"
                variant="info"
                content= {activeId !== null && (
                  <span>Balance: {result1.find((item) => item.id === activeId).balance}</span>
                )}
              />



            </div>











            : null
        }
         {loading === false &&
        result1.length === 0 && (
          <div style={{ textAlign: "center" }}>
            <img
              style={{ maxWidth: "400px" }}
              src="/assets/images/no-data-found.avif"
            />
            <h4 className="mt-4">
            You currently do not possess any NFTs.
            </h4>
          </div>
        )}
      </div>
     

    </div>
  );
};




const mapStateToProps = (state) => ({

  wallet: state.WalletReducers,

})

export default connect(mapStateToProps, {})(TrackFarm)
