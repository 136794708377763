import languageStrings from "../../../language/index";

export default function RoadMap() {
  return (
    <>
      <section id="road-map" className="RoadMap">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 text-center">
              <h4 className="blackheadingtwo mb-5">RoadMap</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="timeline">
                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="timeline-block timeline-block-right">
                    <div className="marker" />

                    <div className="arrow_box">
                      <div className="data">
                        <h3 className="blueheadingtwor">
                          2018
                        </h3>
                        <p className="description">
                          Test Banana Farming 101 Model
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="timeline-block timeline-block-left">
                    <div className="arrow_box_left">
                      <div className="data">
                        <h3 className="blueheadingtwor">2018</h3>
                        <p className="description">
                          Launch first pilot acres and grow to over 700 acres by
                          2023
                        </p>
                      </div>
                    </div>
                    <div className="marker" />
                  </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="timeline-block timeline-block-right">
                    <div className="marker" />

                    <div className="arrow_box">
                      <div className="data">
                        <h3 className="blueheadingtwor">2022</h3>
                        <p className="description">
                          Introduce CloudFarms to expand into Banana Farm 101
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="timeline-block timeline-block-left">
                    <div className="arrow_box_left">
                      <div className="data">
                        <h3 className="blueheadingtwor">2023</h3>
                        <p className="description">
                          Fundraising begins for first 250 acres supported on
                          the blockchain
                        </p>
                      </div>
                    </div>
                    <div className="marker" />
                  </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="timeline-block timeline-block-right">
                    <div className="marker" />

                    <div className="arrow_box">
                      <div className="data">
                        <h3 className="blueheadingtwor">2023</h3>
                        <p className="description">
                          Complete fundraising for 250 acres
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="timeline-block timeline-block-left">
                    <div className="arrow_box_left">
                      <div className="data">
                        <h3 className="blueheadingtwor">2024</h3>
                        <p className="description">
                          CloudFarms acquires farmland on behalf of BNNA holders
                        </p>
                      </div>
                    </div>
                    <div className="marker" />
                  </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="timeline-block timeline-block-right">
                    <div className="marker" />

                    <div className="arrow_box">
                      <div className="data">
                        <h3 className="blueheadingtwor">2024</h3>
                        <p className="description">
                          Key contracts set in and trees are planted along with
                          monitoring devices
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="timeline-block timeline-block-left">
                    <div className="arrow_box_left">
                      <div className="data">
                        <h3 className="blueheadingtwor">2024</h3>
                        <p className="description">
                          First six-monthly dividend airdrop in USDT to BNNA
                          holders
                        </p>
                      </div>
                    </div>
                    <div className="marker" />
                  </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="timeline-block timeline-block-right">
                    <div className="marker" />

                    <div className="arrow_box">
                      <div className="data">
                        <h3 className="blueheadingtwor">2031</h3>
                        <p className="description">
                          Final dividends airdropped for farm BNNA
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="timeline-block timeline-block-left">
                    <div className="arrow_box_left">
                      <div className="data">
                        <h3 className="blueheadingtwor">Ongoing</h3>
                        <p className="description">
                          CMeanWhile other farms are explored and partnerships
                          made with other farms around the world and add to
                          CloudFarms Platfrom
                        </p>
                      </div>
                    </div>
                    <div className="marker" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
