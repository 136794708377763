import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import React, { useEffect, useState } from "react";
import { modalAlert } from "../../utils/alert";
import { connectMetamask } from "../../utils/Blockchain";
import { switchNetwork } from "../../utils/Blockchain";
import { constant } from "../../config/config";
import { wallet, wind } from "fontawesome";
import { ethers } from "ethers";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import {
  FaFacebookF,
  FaInstagram,
  FaPinterest,
  FaQuora,
  FaLinkedinIn,
  FaDiscord
} from "react-icons/fa";
import { FaMedium, FaXTwitter } from "react-icons/fa6";
import {
  saveProvider,
  setNetworkID,
  setWallet,
  setMetamaskConnection,
} from "../../redux/action/WalletAction";
import { store } from "../../store";
import {
  metamaskConnectionProcess,
  metamaskExistAndConnected,
} from "../../utils/util";
import WalletConnect from "../home/component/WalletConnect";
// import SignUpModal from "../dashboard/Modal/SignUpModal";

function Header(props) {
  const [isSticky, setIsSticky] = useState(false);
  const [modalShowSign, setModalShowSign] = React.useState(false);
 
  const history = useHistory();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // useEffect(() => {
  //   setModalShowSign(true)
  //   console.log("fosdifsdj");
  // }, [props.wallet.connectedWallet]);


  return (
    <>
      {/* <SignUpModal
        show={modalShowSign}
        onHide={() => setModalShowSign(false)}
      /> */}

      <Navbar
        // className="custom-nav py-4 position-fixed w-100"
        className={`custom-nav py-4 w-100 sticky-header ${
          isSticky ? "sticky" : ""
        }`}
        bg=""
        expand="lg"
      >
        <Container>
          <Navbar.Brand className="p-0">
          <Nav.Link  href="/">
            <img width={100} src="/assets/imgs/cloudfirmlogo.png" />
          </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />

          <Navbar.Collapse id="navbarScroll">
            <Nav className="mx-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link className="active" href="#sale">Sale</Nav.Link>
              <Nav.Link href="#about-us">About Us</Nav.Link>
              <Nav.Link href="#how-to-buy">How to Buy</Nav.Link>
              <Nav.Link>Roadmap</Nav.Link>
              <Nav.Link href="#faq">FAQ</Nav.Link>
              {/* <Nav.Link href="/CloudFarms_WP_ref.pdf" target="_blank">Whitepaper</Nav.Link> */}
              <Nav.Link href="/BNNA24A_Cloud_Farm_WP_ref.pdf" target="_blank">Whitepaper</Nav.Link>
              {/* <Nav.Link href="#faq">Join Community</Nav.Link> */}
              <NavDropdown
              id="nav-dropdown-dark-example"
              title="Join Community"
              menuVariant="dark"
            >
              <NavDropdown.Item href="https://discord.com/invite/5TSJe5xzS7/" className="header-social"><FaDiscord /><p>Discord</p></NavDropdown.Item>
              <NavDropdown.Item href="https://www.facebook.com/cloudfarmsofficial/" className="header-social"><FaFacebookF /><p>Facebook</p></NavDropdown.Item>
              <NavDropdown.Item href="https://www.instagram.com/cloudfarms.io/" className="header-social"><FaInstagram /><p>Instagram</p></NavDropdown.Item>
              <NavDropdown.Item href="https://www.pinterest.com/bananacloudfarms/" className="header-social"><FaPinterest /><p>Pinterest</p></NavDropdown.Item>
              <NavDropdown.Item href="https://www.quora.com/profile/Cloudfarms" className="header-social"><FaQuora /><p>Quora</p></NavDropdown.Item>
              <NavDropdown.Item href="https://www.linkedin.com/company/cloudfarms-investment/" className="header-social"><FaLinkedinIn /><p>LinkedIn</p></NavDropdown.Item>
              <NavDropdown.Item href="https://medium.com/@cloudfarms.io/" className="header-social"><FaMedium /><p>Medium</p></NavDropdown.Item>
              <NavDropdown.Item href="https://x.com/cloudfarms_" className="header-social"><FaXTwitter /><p>Twitter</p></NavDropdown.Item>
            </NavDropdown>
            </Nav>
            <ul className="d-flex list-inline m-0 align-items-center">
              <li className="socialLinks">
              <WalletConnect  />
              </li>


              <li
                style={{
                  backgroundColor: "#AB7E63",
                  borderRadius: "50%",
                  padding: "10px 2px",
                  marginLeft: "10px",
                }}
              >
                <a href="https://discord.com/invite/5TSJe5xzS7/" target="_blank">
                  <img
                    width={30}
                    className="mx-2"
                    src="/assets/images/4d3420a4f1a5e94295ef681adc541b1c-removebg-preview.png"
                    alt="discord"
                  />
                </a>
              </li>
            </ul>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
const mapStateToProps = (state) => ({
  wallet: state.WalletReducers,
});

export default connect(mapStateToProps, {})(Header);
