import invoke from "../utils/invoke";

export const getUsers = () => {
    return invoke({ 
        url: `cloud-farms`
     })
}
export const saveUser = (data) => {
    return invoke({ 
        method: 'POST',
        url: `cloud-farms`,
        data: {data:data}
     })
}