import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormErrorAlert, FormSuccessAlert } from "../../../utils/alert";
import { buyCFC, transactionApprove } from "../../../utils/Blockchain";
import { isEmpty } from "lodash";
import axios from "axios";
import { connect } from "react-redux";
import { Loader } from "../../../utils/alert";
import { getUSDTBalance } from "../../../utils/Blockchain";
import { constant } from "../../../config/config";
import { saveUser } from "../../../api/dataSave";

//https://www.davidhu.io/react-spinners/

const SignUpModal = (props) => {
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [referral, setReferral] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [isError, setIsError] = useState(false)
  const [disable, setDisable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [suceess, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")

  let resetState = () => {
    setIsError(false)
    setLoading(false)
    setDisable(false)
    setErrorMessage("")
    setFullName("")
    setEmail("")
    setReferral("")
    setSuccess(false)
    setSuccessMessage("")
  }




  let save = async () => {
    try{
        setLoading(true)
        var data = {fullName, email, address: props.wallet.connectedWallet}
        const result = await saveUser(data)
        console.log(result);
        setSuccess(true)
        setSuccessMessage("User Added!")
        setLoading(false)
        // props.onHide()

    }catch(e){
        console.log(e);
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Enter Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{
        wordWrap: 'break-word',

      }}>
        <div className="row">
            <div className="row">

          <div className="col-6 mb-3">
            <label className="mb-2">Full Name</label>
            <input
              type="text"
              className="form-control"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Enter Full Name."
              />
          </div>
          <div className="col-6 mb-3">
            <label className="mb-2">Email</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Email."
              />
          </div>
              </div>
          <div className="col-12 mb-3">
            <label className="mb-2">MetaMask Address</label>
            <input
              placeholder="Please enter referral address."
              onChange={(e) => setReferral(e.target.value)}
              value={props.wallet.connectedWallet}
              type="text" className="form-control" />
          </div>
          <div className="col-12 mb-3">
            {isError && <FormErrorAlert message={errorMessage} />}
          </div>

          <div className="col-12 mb-3">
            {suceess && <FormSuccessAlert message={successMessage} />}
          </div>

          <div className="col-12 mb-3">
            <button onClick={save}
              disabled={disable}
              style={{ backgroundColor: "#019348", border: "none" }}
              className="btn w-100 py-2 btn-primary"
            >
              {loading === true ? <Loader color="#36d7b7"></Loader> : "Proceed"}

            </button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={disable}
          style={{ backgroundColor: "#019348", border: "none" }}
          onClick={() => {
            resetState()
            props.onHide()
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};



const mapStateToProps = (state) => ({

  wallet: state.WalletReducers,
  dashboard: state.DashboardReducers
})

export default connect(mapStateToProps, {})(SignUpModal)
