import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Mobilemenu = () => {
  // useEffect(() => {
  //   document.getElementById('dashboard-div').onclick = () => {
  //     setIsOpen(false);
  //   }
  // }, [])

  const [isopen, setIsOpen] = useState(false);

  const handleClick = (e) => {
    setIsOpen(!isopen);
  };
  const [activeLink, setActiveLink] = useState("dashboard");
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <div className="mobile-menu-main">
      <div className="mobile-nav">
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="d-flex align-items-center justify-content-between"
        >
          <img
            width={100}
            src="/assets/images/logo-in-solution.png"
            alt="dashlogo"
          />
          <img
            style={{ cursor: "pointer" }}
            onClick={handleClick}
            src="/assets/imgs/humber.png"
            alt="humber"
          />
        </div>
        {isopen && (
          <div className="mobile-links">
            <ul className="pt-2">
              <li>
                <Link
                  to="/home"
                  className={` ${
                    activeLink === "dashboard" ? "isActives" : ""
                  }`}
                  onClick={() => handleLinkClick("dashboard")}
                  style={{ cursor: "pointer" }}
                >
                  <p>Dashboard</p>
                </Link>
              </li>
              <li>
                <Link
                  to="/transaction-history"
                  className={` ${activeLink === "History" ? "isActives" : ""}`}
                  onClick={() => handleLinkClick("History")}
                  style={{ cursor: "pointer" }}
                >
                  <p>Transaction History</p>
                </Link>
              </li>
              <li>
                <Link
                  to="/referal"
                  className={` ${activeLink === "swap" ? "isActives" : ""}`}
                  onClick={() => handleLinkClick("swap")}
                  style={{ cursor: "pointer" }}
                >
                  <p>Referred</p>
                </Link>
              </li>
              <li>
                <Link
                  to="/trackfarm"
                  className={` ${activeLink === "track" ? "isActives" : ""}`}
                  onClick={() => handleLinkClick("track")}
                  style={{ cursor: "pointer" }}
                >
                  <p>Track Farm</p>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Mobilemenu;
