 import React from "react";
 import ReactDOM from "react-dom/client";
 import App from "./App";
 import { Provider } from 'react-redux';
// import store from "./store";

// import { PersistGate } from 'redux-persist/integration/react';

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <Provider store={store}> 
//     <App />
   
  
//   </Provider>
// );


import  store  from './store'; // Import the store and persistor



// ...

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
   
      <App />
   
  </React.Fragment>,
);
