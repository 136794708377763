import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import WalletConnect from "../component/WalletConnect";
export default function GifSlider() {
  const options = {
    items: 1,
    loop: true,
  };

  return (
    <>
      <section className="Slider">
        <OwlCarousel className="owl-theme" {...options}>
          <div className="item">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div class="content ">
                    <h5 className="blackheading mb-4">
                      An Agriculture Real Estate Investment Trust (REIT)
                    </h5>
                    <p className="description m-0 mb-4">
                      Cloud Farms bridges the gap between investors and the
                      agriculture business. Become inflation-proof by generating
                      cash flows from farming
                    </p>
                    <WalletConnect />
                    {/* <a className="btn mt-4 btn-custom">Learn More</a> */}
                  </div>
                </div>
                <div className="col-lg-6 d-md-none d-lg-block">
                  <img src="/assets/imgs/slide1.png" alt="" className="w-100" />
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div class="content ">
                    <h5 className="blackheading mb-4">
                      Own cash-generating acres around the world
                      {/* <span className="brownheading"></span>  */}
                    </h5>
                    <p className="description m-0 mb-4">
                      Diversify in an inflation-free portfolio in real estate
                      and agriculture earning up to 33% APR
                    </p>
                    <WalletConnect />

                    {/* <a className="btn mt-4 btn-custom">Learn More</a> */}
                  </div>
                </div>
                <div className="col-lg-6 d-md-none d-lg-block">
                  <img src="/assets/imgs/slide1.png" alt="" className="w-100" />
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </section>
    </>
  );
}
