import { useEffect, useState } from "react";
import { getInitialInfo } from "../../../utils/Blockchain";
import { constant } from "../../../config/config";
import { connect } from "react-redux";


function Supply(props) {
  const [priceOfOneCFC, setPriceOfOneCFC] = useState(0);

  useEffect(() => {
    let getBalances = async () => {
      try {
        console.log(props.wallet, "props.wallet");
        let getSingleCFCPrice = await getInitialInfo(props.wallet.provider);
        let priceOf160CFC = parseInt(getSingleCFCPrice[0]) / constant.decimel;
        let priceOfOneCFC = priceOf160CFC / 160;

        setPriceOfOneCFC(priceOfOneCFC);
      } catch (e) {}
    };
    getBalances();
  });

  return (
    <>
      <section id="supply" className="supply">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 text-center">
              <h4 className="blackheadingtwo mb-5">Supply</h4>
            </div>
          </div>
          <div className="row">
            <div className="col12">
              <div className="network-main">
                <div className="d-flex justify-content-between align-items-center"></div>

                <div className="inner-network mt-3 p-3">
                  <div
                    className="row p-3"
                    style={{ border: "3px solid #ab7e63" }}
                  >
                    <div className="col-lg-12 col-md-12">
                      <div className="row">
                        {/* <div className="col-lg-4 col-md-4 col-4">
                          <p>Remaning Supply</p>
                          <h4>0</h4>
                        </div> */}
                        {/* <hr /> */}

                        {/* <div className="col-lg-4 col-md-4 col-4">
                          <p>Sale Supply</p>
                          <h4>0</h4>
                        </div> */}
                        {/* <hr /> */}
                        {/* <div className="col-lg-4 col-md-4 col-4">
                          <p>Total Supply</p>
                          <h4>0</h4>
                        </div> */}
                      </div>
                      {/* <hr /> */}
                      <div className="col-lg-12">
                        <div className="row">
                          {/* <div className="col-lg-4 col-md-4 col-4">
                            <p>Price In MATIC</p>

                            <h4>0</h4>
                          </div> */}
                          <div className="d-flex justify-content-between">
                            <div className="col-lg-6 col-md-6 col-6">
                              <p>Total Supply</p>
                              <h4>112,000</h4>
                            </div>
                            <hr />
                            <div class="col-lg-6 col-md-6 col-6">
                              <p>Price In USDT</p>
                              <div>
                                <h4>${priceOfOneCFC}</h4>
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-lg-4 col-md-4 col-4">
                            <p>Remaining Amount</p>
                            <h4>0</h4>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}




const mapStateToProps = (state) => ({
  wallet: state.WalletReducers,
});

export default connect(mapStateToProps, {})(Supply);