
import { PROVIDER, SET_NETOWRK_ID, SET_WALLET, METAMASK_CONNECTION, METAMASK_PROVIDER, USER_WALLET_USDT_BALANCE, USER_WALLET_BNB_BALANCE, CURRENT_TAB, SET_NFT_BALANCE } from "../types/WalletTypes"
let initialState = {
    provider: {},
    networkID: null,
    metamaskConnected: null,
    connectedWallet: null,

   




    metamaskProvider: {},
    userWalletUsdtBalance: 0,
    userWalletBNBBalance: 0,
   
}
export const WalletReducers = (state = initialState, action) => {
    switch (action.type) {
        case PROVIDER:
            return {
                ...state,
                provider: action.payload
            }
        case SET_NETOWRK_ID:
            return {
                ...state,
                networkID: action.payload
            }
        case SET_WALLET:
            return {
                ...state,
                connectedWallet: action.payload
            }
        case METAMASK_CONNECTION:
            return {
                ...state,
                metamaskConnected: action.payload
            }

        case METAMASK_PROVIDER:
            return {
                ...state,
                metamaskProvider: action.payload
            }
        case USER_WALLET_USDT_BALANCE:
            return {
                ...state,
                userWalletUsdtBalance: action.payload
            }
        case USER_WALLET_BNB_BALANCE:
            return {
                ...state,
                userWalletBNBBalance: action.payload

            }
        case CURRENT_TAB:
            return {
                ...state,
                currentTab: action.payload

            }


        default:
            return state
    }

}





