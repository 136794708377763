import languageStrings from "../../../language/index";

export default function Solutions() {
  return (
    <>
      {/* <section className="Problems Solutions">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div>
                <h4 className="whiteheadingtwo mb-2">
                  {languageStrings["Solutions"]}
                </h4>
                <p className="descriptionTwo mb-5">
                  {
                    languageStrings[
                      "Many communities, companies, organizations"
                    ]
                  }
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 solution-m">
              <div>
                <div className="content solution-content mb-5">
                  <div className="solution-inner">
                    <div className="Box text-center">
                      <img
                        src="/assets/images/logo-in-solution.png"
                        className="mb-4 coin-md"
                        alt=""
                      />
                      <p className="descriptionTwo mb-0">
                        {
                          languageStrings[
                            "Estate Upcare aims to create a platform"
                          ]
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 solution-m">
              <div>
                <div className="banner solution-banner">
                  <div className="BannerContent mb-2">
                    <p className="descriptionTwo m-0">
                      <b style={{ fontWeight: "700" }}>
                        {languageStrings["Blockchain technology"]}
                      </b>
                      {languageStrings["ensures transparency, immutability,"]}
                    </p>
                  </div>
                  <div className="BannerContent mb-2">
                    <p className="descriptionTwo m-0">
                      <b style={{ fontWeight: "700" }}>
                        {" "}
                        {languageStrings["Web 3.0"]}
                      </b>
                      {languageStrings["technology further enhances"]}
                    </p>
                  </div>
                  <div className="BannerContent">
                    <p className="descriptionTwo m-0">
                      {languageStrings["By combining the security"]}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section  className="Problems Solutions">
<div className="container">

      <img className="w-100" src="/assets/images/introducingcloudfarm.jpg" alt="introducingcloudfarm" />
</div>
      </section>
    </>
  );
}
