import React, { useEffect } from "react";
import WithdrawModal from "./Modal/WithdrawModal";
import DepositModal from "./Modal/DepositModal";
// import SignUpModal from "./Modal/SignUpModal";
import { connect } from "react-redux";
import { getBuyInfo, getNftBalances, getTime } from "../../utils/Blockchain";
import { modalAlert } from "../../utils/alert";
import { setNftBalances } from "../../redux/action/DashBoardAction";
import store from "../../store";
import { constant } from "../../config/config";

import { isEmpty } from "lodash"
import { BarLoader } from "react-spinners";
import { SET_LOADING } from "../../redux/types/WalletTypes";
import { useFeeData } from "wagmi";

const DashboardHome = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowDeposit, setModalShowDeposit] = React.useState(false);
  const [modalShowSign, setModalShowSign] = React.useState(false);
  const [usdtInputValue, setUsdtInputValue] = React.useState(0);
  const [copyInputValue, setCopyInputValue] = React.useState(0);
  const [loading, setLoading] = React.useState(true)
  //const [ids, setID] = React.useState([])
 // const [address, setAddress] = React.useState([])
  const [result1, setResult] = React.useState([])

 



  useEffect(() => {

    let getTransactionHistory = async () => {
      try {
        //store.dispatch({ type: SET_LOADING, payload: true })

        if (!isEmpty(props.wallet.provider)) {

          let nestedArray = await getBuyInfo(props.wallet.provider, props.wallet.connectedWallet);
          // Extract unique IDs from the nestedArray
          const uniqueIds = [...new Set(nestedArray[0].map(item => item.toString()))];
          console.log("news", nestedArray, uniqueIds)
          // Create an array of unique IDs as numbers
          const idsArray = uniqueIds.map(id => parseInt(id)); // Assuming you want the IDs as numbers
         // setID(idsArray);
          const addressArray = Array(idsArray.length).fill(props.wallet.connectedWallet);
         // setAddress(addressArray);
          let response = await getNftBalances(props.wallet.provider, addressArray, idsArray);
          const data = idsArray.map((id, index) => ({
            id: id,
            balance: response[index].toString(),
          }));




          setResult(data)
          setTimeout(() => {
            setLoading(false)
          }, 2000);
          // store.dispatch(setNftBalances(result))
          // store.dispatch({ type: SET_LOADING, payload: false })
        }
      }
      catch (e) {
        // store.dispatch({ type: SET_LOADING, payload: false })
        setLoading(false)
        let msg = e.message ? e.message : "We are facing some issues to get the history."
        modalAlert('', msg, 'error')

      }
    }
    getTransactionHistory()

    return () => {
      //  alert("i am hit")
      setResult([])
      // This code will run when the component is unmounted.
      // You can perform cleanup tasks here, such as clearing intervals, canceling requests, etc.
    };
  }, [props.wallet])


  return (
    <div className="inner-main">
      {console.log("wait", props.dashboard)}
      <WithdrawModal show={modalShow} onHide={() => setModalShow(false)} />
      <DepositModal
        show={modalShowDeposit}
        onHide={() => setModalShowDeposit(false)}
      />

      <div className="col-lg-12">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h1 className="main-heading">Home</h1>
          </div>
          <div>
            <button
              style={{ backgroundColor: "#019348", border: "none" }}
              className="btn btn-primary me-3"
              onClick={() => setModalShowDeposit(true)}
            >
              Buy
            </button>
            <button
              style={{ backgroundColor: "#019348", border: "none" }}
              className="btn btn-primary"
              onClick={() => setModalShow(true)}
            >
              Transfer
            </button>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-12">
            <label className="sub-heading mb-2" htmlFor="">
              Wallet Address
            </label>
            <div class="card">
              <div class="card-body">
                <h5 className="m-0">
                  {props.wallet.connectedWallet}
                  {/* <span>
                    <img
                      src="/assets/images/copy.png"
                      style={{ maxWidth: "30px" }}
                      alt=""
                    />
                  </span>{" "} */}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title m-0">Calculator</h5>
                <p class="card-text mb-3">Calculate tokens in an instant</p>
                <hr />
                <div className="row">
                  <div className="col-12">
                    <label className="mb-3" htmlFor="">
                      You Have
                    </label>
                  </div>
                  <div className="col-6">
                    <input
                      style={{ height: "60px" }}
                      type="number"
                      value={usdtInputValue}
                      className="mt-0 p-3"
                      placeholder="0.00"
                      onChange={(e) => {
                        setUsdtInputValue(e.target.value)

                        setCopyInputValue(e.target.value / props?.dashboard?.priceOfOneCopy)


                      }}
                    />
                  </div>
                  <div className="col-6">
                    <select
                      style={{
                        height: "60px",
                        backgroundColor: "#F8F8F8",
                        color: "#000",
                      }}
                      className="w-100 p-3 border-0 rounded-3"
                      name=""
                      id=""
                    >
                      <option value="">USDT</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center my-3">
                    <img
                      style={{ maxWidth: "50px" }}
                      src="/assets/img/arrow.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label className="mb-3" htmlFor="">
                      You Get
                    </label>
                  </div>
                  <div className="col-6">
                    <input
                      style={{ height: "60px" }}
                      type="number"
                      value={copyInputValue}
                      onChange={(e) => {
                        setCopyInputValue(e.target.value)
                        setUsdtInputValue(e.target.value * props?.dashboard?.priceOfOneCopy)


                      }}

                      className="mt-0 p-3"
                      placeholder="0.00"
                    />
                  </div>
                  <div className="col-6">
                    <select
                      style={{
                        height: "60px",
                        backgroundColor: "#F8F8F8",
                        color: "#000",
                      }}
                      className="w-100 p-3 border-0 rounded-3"
                      name=""
                      id=""
                    >
                      <option value="">BNNA</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {
            loading === true ? <div className="col-lg-6">  <BarLoader width={"100%"} color="#36d7b7" /></div> : result1.length > 0 ?


              <div className="col-lg-6">
                <div class="card">
                  <div class="card-body">
                    {/* <p>ddddd</p>
                <p>{props?.dashboard?.priceOfOneCopy}</p> */}

                    <h5 class="card-title m-0">NFT Market</h5>
                    <p class="card-text mb-3">NFT Quantity List</p>
                    <hr />
                    <div className="table-responsive">
                      <table className="table custom-table">
                        <thead>
                          <tr className="bg-table">
                            <th>NFT Id's</th>
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>

                        {result1.map((item) => (
                              // Use a conditional statement to check if item.CFC is not equal to 0 before rendering the row.
                              parseInt(item.balance) !== 0 && (
                            <tr>

                              <td>{item.id}</td>
                              <td>{item.balance} BNNA</td>
                            </tr>

                          )))}


                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div> :





              <div className="col-lg-6">
                <div class="card">
                  <div style={{flexDirection:"column"}} class="card-body d-flex align-items-center justify-content-center">
                    <img
              style={{ maxWidth: "285px" }}
              src="/assets/images/no-data-found.avif"
            />
                    <h4 className="mt-4">
                    At the moment, there are no existing NFTs.
                    </h4>
                  </div>
                </div>
              </div>
          }

          {/* 
{loading === false &&
          result.length === 0 && (
            <div style={{ textAlign: "center" }}>
              <img
                style={{ maxWidth: "400px" }}
                src="/assets/images/no-data-found.avif"
              />
              <h4 className="mt-4">
                There are no existing transaction at the moment
              </h4>
            </div>
          )} */}









        </div>
      </div>

      {/* {loading === false &&
        result.length === 0 && (
          <div style={{ textAlign: "center" }}>
            <img
              style={{ maxWidth: "400px" }}
              src="/assets/images/no-data-found.avif"
            />
            <h4 className="mt-4">
              There are no existing transaction at the moment
            </h4>
          </div>
        )} */}



    </div>
  );
};



const mapStateToProps = (state) => ({

  wallet: state.WalletReducers,
  dashboard: state.DashboardReducers
})

export default connect(mapStateToProps, {})(DashboardHome)





