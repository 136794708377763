import languageStrings from "../../../language/index";

export default function Buy() {
  return (
    <>
      <section id="how-to-buy" className="Buy position-relative">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <div>
                <h4 className="blackheadingtwo mb-5">
                  {languageStrings["How to Buy"]}
                </h4>
                {/* <p className="description mb-5">
                  {languageStrings["The Estate Upcare token"]}
                </p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div>
                <div className="content content-card mb-4">
                  <div className="Box">
                    <img
                      src="/assets/images/bigcoin.png"
                      className="mb-4"
                      alt=""
                    />
                    <h4 className="blueheadingtwoc mb-3">Step 1 :</h4>
                    <p className="descriptionTwo mb-0">
                      Create a wallet on “Metamask” or a similar.*Available on:
                      AppStore, Play Store, Windows, iOS. Make sure to back up
                      your wallet in case you lose your device.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <div className="content content-card mb-4">
                  <div className="Box">
                    <img
                      src="/assets/images/usdt.png"
                      className="mb-4"
                      alt=""
                    />
                    <h4 className="blueheadingtwoc mb-3">Step 2 :</h4>
                    <p className="descriptionTwo mb-0">
                      Log In to your dashboard on
                      <a color="black!important" href="https://cloudfarms.io/">
                        {" "}
                        www.CloudFarms.io
                      </a>
                      {""} and connecting your wallet. You can access your
                      dashboard to buy, track and manage your farm
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <div className="content content-card mb-4">
                  <div className="Box">
                    <img
                      src="/assets/images/card.png"
                      className="mb-4"
                      alt=""
                    />
                    <h4 className="blueheadingtwoc mb-3">Step 3 :</h4>
                    <p className="descriptionTwo mb-0">
                      Complete your purchase and refer friends and family to
                      receive your profits as they come to fruition in USD Coin.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
