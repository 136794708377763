import { Link } from "react-router-dom";

export default function Podcast() {
  return (
    <>
      <section id="podcast" className="Buy position-relative">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <div>
                <h4 className="blackheadingtwo mb-5">
                  Cloudfarms Podcast
                </h4>

              </div>
            </div>
          </div>

          <div className="row">
            <Link to="/podcast" className="mx-auto d-flex justify-content-center">
              <img
                className="podcast-img mx-auto"
                src="assets\imgs\pod-thumb.jpeg"
                alt=""
              />
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
