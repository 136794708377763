import languageStrings from "../../../language/index";

export default function Packages() {
  return (
    <>
      <section
        id="about-us"
        className="AboutUs d-flex align-items-center position-relative"
      >
        <div className="container">
          <div className="row align-items-center">
          <div className="col-lg-6 col-md-6">
              <div className="mb-5">
                <div className="banner">
                  <img
                    src="\assets\imgs\packages.jpeg"
                    alt=""
                    className="w-100"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div>
                <div className="content">
                  <h4 className="blackheadingtwo mb-3">
                    {languageStrings["packages"]}
                  </h4>
                  <p className="description mb-5">
                    Are you ready to reap substantial profits in 2024?
                    CloudFarms.io is your gateway to smart and profitable
                    investments in banana farming in 2024. With our innovative
                    platform, we offer three investment packages to different
                    levels of investors. These packages show options that are
                    perfect for any type of investor. Our Silver Package is
                    ideal for those who are small investors and want to start
                    with a small amount but dream big. For just 1 BNNA, you can
                    invest in 1 Marla of fertile land. It is the beginning of
                    your journey in the lucrative world of banana farming. It’s
                    a low-risk but that promises high rewards. Don’t hesitate to
                    take the first step toward financial success. Gold Package
                    is the perfect choice if you want to scale up your
                    investors. For 160 BNNA, you can buy 1 Acre of prime
                    agricultural land for the staple fruit banana. This package
                    is designed for those who are ready to take their
                    investments to the next level of profit.<br/> This offers greater
                    returns and the opportunity to increase your profits. Grab
                    this chance to grow your wealth now. Diamond Package is
                    unmatched for those who are seeking maximum profit with
                    maximum investment. With an investment of 16,000 BNNA, you
                    can acquire an enormous 100 Acres of productive banana
                    farmland. This package is for those who are committed to
                    making substantial gains and capitalizing on the expanding
                    banana farming industry. It's an unmatched opportunity to
                    ensure long-term financial stability and success. Don’t
                    wait—order now! CloudFarms.io makes investment in banana
                    farms easy, accessible, and highly profitable. Our platform
                    simplifies the process, offering clear and attractive
                    packages that cater to every type of investor. Don’t miss
                    out on this extraordinary opportunity to secure your
                    financial future with a smart investment in banana farming.
                    Choose the package that’s right for you. Invest now and
                    start reaping the benefits of your wise decision!
                  </p>

                </div>
              </div>
            </div>


          </div>
        </div>
      </section>
    </>
  );
}
