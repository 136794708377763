import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormErrorAlert, FormSuccessAlert } from "../../../utils/alert";
import { buyCFC, transactionApprove } from "../../../utils/Blockchain";
import { isEmpty } from "lodash";
import axios from "axios";
import { connect } from "react-redux";
import { Loader } from "../../../utils/alert";
import { getUSDTBalance } from "../../../utils/Blockchain";
import { constant } from "../../../config/config";

//https://www.davidhu.io/react-spinners/

const DepositModal = (props) => {
  const [numbofCopy, setNumbOfCopy] = useState(0)
  const [referral, setReferral] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [isError, setIsError] = useState(false)
  const [disable, setDisable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [suceess, setSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")

  let resetState = () => {
    setIsError(false)
    setLoading(false)
    setDisable(false)
    setErrorMessage("")
    setNumbOfCopy(0)
    setReferral("")
    setSuccess(false)
    setSuccessMessage("")
  }




  let buyCopy = async () => {
    setSuccess(false)
    if (parseInt(numbofCopy) <= 0 || isEmpty(numbofCopy)) {
      setIsError(true)
      setErrorMessage("The BNNA amount must exceed zero.")
      return
    }
    // if (parseFloat(props?.dashboard?.currencyBalance) <= 0.01) {
    //   setIsError(true)
    //   setErrorMessage("To complete a transaction, you must have a minimum balance of 0.01 ETH in your account");
    //   return;
    // }

    // if (parseFloat(props?.dashboard?.usdtBalance) < parseFloat(numbofCopy) * parseFloat(props?.dashboard?.priceOfOneCopy)) {
    //   setIsError(true)
    //   setErrorMessage("You must top up your balance before proceeding with the transaction, as your current balance is low");
    //   return;
    // }

    if (referral.toLowerCase() === props.wallet.connectedWallet.toLowerCase()) {
      setIsError(true);
      setErrorMessage("Select a distinct referral wallet; self-referrals are not permitted.");
      return;

    }

    try {
      let referallAddress = isEmpty(referral) ? constant.MARKETING_REFERAL_ADDRESS : referral
     

      setIsError(false)
      setErrorMessage("")
      setLoading(true)
      setDisable(true)
      await transactionApprove((parseFloat(numbofCopy) * parseFloat(props?.dashboard?.priceOfOneCopy)).toString(), props.wallet.provider)
      await buyCFC(props.wallet.provider, numbofCopy, referallAddress);

      setLoading(false)
      setDisable(false)
      setSuccess(true)
      setNumbOfCopy(0)
      setReferral("")
      setSuccessMessage("Congratulations, your purchase of the BNNA has been successful!")
    }
    catch (e) {
      setLoading(false)
      setDisable(false)

      if (e?.data) {
        let msg = e?.data?.message
        setIsError(true)
        setErrorMessage(msg)
      }
      else {
        let msg = e.message ? e.message : "We are facing some issues when trying to buy BNNA."
        setIsError(true)
        setErrorMessage(msg)
      }
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Buy BNNA
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{
        wordWrap: 'break-word',

      }}>
        <div className="row">
          <div className="col-12 mb-3">
            <label className="mb-2">Enter the BNNA amount.</label>
            <input
              type="number"
              className="form-control"
              value={numbofCopy}
              onChange={(e) => setNumbOfCopy(e.target.value)}
              placeholder="Please enter the BNNA number you wish to purchase."
            />
          </div>
          <div className="col-12 mb-3">
            <label className="mb-2">Enter Referral Address</label>
            <input
              placeholder="Please enter referral address."
              onChange={(e) => setReferral(e.target.value)}
              value={referral}
              type="text" className="form-control" />
          </div>
          <div className="col-12 mb-3">
            {isError && <FormErrorAlert message={errorMessage} />}
          </div>

          <div className="col-12 mb-3">
            {suceess && <FormSuccessAlert message={successMessage} />}
          </div>

          <div className="col-12 mb-3">
            <button onClick={buyCopy}
              disabled={disable}
              style={{ backgroundColor: "#019348", border: "none" }}
              className="btn w-100 py-2 btn-primary"
            >
              {loading === true ? <Loader color="#36d7b7"></Loader> : " Buy BNNA"}

            </button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={disable}
          style={{ backgroundColor: "#019348", border: "none" }}
          onClick={() => {
            resetState()
            props.onHide()
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};



const mapStateToProps = (state) => ({

  wallet: state.WalletReducers,
  dashboard: state.DashboardReducers
})

export default connect(mapStateToProps, {})(DepositModal)
