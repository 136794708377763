import languageStrings from "../../../language/index";

export default function Guide() {
  return (
    <>
      <section id="how-to-buy" className="Slider">
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <div>
                <h4 className="blackheadingtwo mb-5">
                  Tutorial: How to Access the Dashboard
                </h4>
    
              </div>
            </div>
          </div>

          <div className="row">
            <video width="320" height="640" controls>
              <source src="\assets\vedios\guideVedio.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <div>
                <h4 className="blackheadingtwo mb-5">
                USDT Dividends In 6 Months
                </h4>
    
              </div>
            </div>
          </div>

          <div className="row">
            <video width="320" height="640" controls>
              <source src="\assets\vedios\USDTDividendsIn6Months.mp4" type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <div>
                <h4 className="blackheadingtwo mb-5">
                Transparent Farming Investment
                </h4>
    
              </div>
            </div>
          </div>

          <div className="row">
            <video width="320" height="640" controls>
              <source src="\assets\vedios\transparent farming investment.mp4" type="video/mp4" />
            </video>
          </div>
        </div>


        {/* <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <div>
                <h4 className="blackheadingtwo mb-5">
                Grow Your Crypto
                </h4>
    
              </div>
            </div>
          </div>

          <div className="row">
            <video width="320" height="640" controls>
              <source src="/assets/vedios/grow your crypto.mp4" type="video/mp4" />
            </video>
          </div>
        </div> */}

        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <div>
                <h4 className="blackheadingtwo mb-5">
                financial freedom formula
                </h4>
    
              </div>
            </div>
          </div>

          <div className="row">
            <video width="320" height="640" controls>
              <source src="\assets\vedios\financial freedom formula.mp4" type="video/mp4" />
            </video>
          </div>
        </div>


        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <div>
                <h4 className="blackheadingtwo mb-5">
                best agricultural investment opportunity
                </h4>
    
              </div>
            </div>
          </div>

          <div className="row">
            <video width="320" height="640" controls>
              <source src="\assets\vedios\best agricultural investment opportunity.mp4" type="video/mp4" />
            </video>
          </div>
        </div>

         
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <div>
                <h4 className="blackheadingtwo mb-5">
                Sustainable And Ethical Investment
                </h4>
    
              </div>
            </div>
          </div>

          <div className="row">
            <video width="320" height="640" controls>
              <source src="\assets\vedios\sustainable and ethical investment.mp4" type="video/mp4" />
            </video>
          </div>
        </div>


        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <div>
                <h4 className="blackheadingtwo mb-5">
                Profitable Land Investment
                </h4>
    
              </div>
            </div>
          </div>

          <div className="row">
            <video width="320" height="640" controls>
              <source src="\assets\vedios\Profitable Land Investment.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <div>
                <h4 className="blackheadingtwo mb-5">
                Generate cashflow with CloudFarms
                </h4>
    
              </div>
            </div>
          </div>

          <div className="row">
            <video width="320" height="640" controls>
              <source src="\assets\vedios\Generate cashflow with CloudFarms.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </section>
    </>
  );
}
