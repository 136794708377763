import React, { useEffect, useState, useRef } from "react";
import Sidebar from "./Sidebar";
import Mobilemenu from "./Mobilemenu";
import Topbar from "./Topbar";
import History from "./History";
import { connect } from "react-redux";
import { isEmpty } from "lodash"

// import { Switch, Route, useLocation } from "react-router-dom";
import {
  Route,
  Switch,
  BrowserRouter,
  useLocation,
  useParams,
} from "react-router-dom";
import DashboardHome from "./Home";
import { constant } from "../../config/config";

import Referal from "./Referal";

const Dashboard = (props) => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const initialWalletRef = useRef(props.wallet);
  const [path, setPath] = useState("");
  // const handleResize = () => {
  //   setIsMobileMenuVisible(window.innerWidth <= 991);
  // };

  const { id } = useParams();

 

  useEffect(() => {
    setPath(id);
  }, [id]);

  return (
    // <div id="dashboard-div">
    //   <Sidebar />
    //   {isMobileMenuVisible && <Mobilemenu />}

    //   <div class="main">
    //     <Topbar />
         <DashboardHome />
    //     {/* {path === "transaction-history" && <History />}
    //     {path === "referal" && <Referal />} */}
    //   </div>
    // </div>
  );
};



const mapStateToProps = (state) => ({

  wallet: state.WalletReducers,

})

export default connect(mapStateToProps, {})(Dashboard)
