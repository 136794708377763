import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [activeLink, setActiveLink] = useState("dashboard");
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };


  return (
    <div>

      <div className={`overlay ${sidebarVisible ? "visible" : ""}`} onClick={toggleSidebar}></div>
      <div className={`navigation ${sidebarVisible ? "active" : ""}`}>
        <div className="sidebar-content">
          <a href="" className="brand">
            <img
              src="/assets/images/logo-in-solution.png"
              style={{ maxWidth: "150px" }}
              alt="dashlogo"
            />
          </a>
          <Link
            to="/home"
            className={`links ${activeLink === "dashboard" ? "isActive" : ""}`}
            onClick={() => handleLinkClick("dashboard")}
            style={{ cursor: "pointer" }}
          >
            <p>Dashboard</p>
          </Link>
          <Link
            to="/transaction-history"
            className={`links ${activeLink === "History" ? "isActive" : ""}`}
            onClick={() => handleLinkClick("History")}
            style={{ cursor: "pointer" }}
          >
            <p>Transaction History</p>
          </Link>
          <Link
            to="/referal"
            className={`links ${activeLink === "swap" ? "isActive" : ""}`}
            onClick={() => handleLinkClick("swap")}
            style={{ cursor: "pointer" }}
          >
            <p>Referred</p>
          </Link>
          <Link
            to="/trackfarm"
            className={`links ${activeLink === "track" ? "isActive" : ""}`}
            onClick={() => handleLinkClick("track")}
            style={{ cursor: "pointer" }}
          >
            <p>Track Farm</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
