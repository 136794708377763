import { PROVIDER, SET_NETOWRK_ID, SET_WALLET, METAMASK_PROVIDER, METAMASK_CONNECTION, USER_WALLET_USDT_BALANCE, USER_WALLET_BNB_BALANCE } from "../types/WalletTypes"
// import { getTokenBalance, getBNBBalance } from "../../blockchain"
// import { config } from "../../config"


// utilized
export const saveProvider = (provider) => {
    return {
        type: PROVIDER,
        payload: provider
    }
}
// utilized
export const setNetworkID = (networkID) => {
    return {
        type: SET_NETOWRK_ID,
        payload: networkID
    }
}
// utilized
export const setWallet = (wallet) => {
    return {
        type: SET_WALLET,
        payload: wallet
    }
}
export const setMetamaskConnection = (value) => {
    return {
        type: METAMASK_CONNECTION,
        payload: value
    }
}


export const saveMetamaskProvider = (provider) => {
    return {
        type: METAMASK_PROVIDER,
        payload: provider
    }
}



export const setUserWalletUsdtBalance = (usdtAddress, walletAddress, myProvider) => async (dispatch) => {

    // try {
    //     const { balanceToken } = await getTokenBalance(usdtAddress, walletAddress, myProvider);
    //     dispatch({
    //         type: USER_WALLET_USDT_BALANCE,
    //         payload: balanceToken,
    //     })

    // } catch (err) {
    //     dispatch({
    //         type: USER_WALLET_USDT_BALANCE,
    //         payload: 0,
    //     })

    // }
}


export const setUserWalletBNBBalance = (walletAddress, myProvider) => async (dispatch) => {

    // try {
    //     const { balance, formateBalance } = await getBNBBalance(walletAddress, myProvider);
    //     dispatch({
    //         type: USER_WALLET_BNB_BALANCE,
    //         payload: parseFloat(balance / config.currencyBalanceDecimel),
    //     })

    // } catch (err) {
    //     dispatch({
    //         type: USER_WALLET_BNB_BALANCE,
    //         payload: 0,
    //     })

    // }
}




