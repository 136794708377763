
import { Alert } from "react-bootstrap";
import Swal from 'sweetalert2'
import { PulseLoader, BeatLoader } from "react-spinners";

export function modalAlert(msg, title = "Good job!", alertType) {
    Swal.fire(title, msg, alertType);

}

export function yesOrNoModalAlert(title) {
    return new Promise((resolve) => {
        Swal.fire({
            title: title,

            //showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Continue',
            customClass: {
                title: 'my-custom-title-class', // Add a custom CSS class for the title
            },
            //  ...customStyles, // Allow custom styles to be passed

        }).then((result) => {
            if (result.isConfirmed) {
                resolve('yes');
            } else if (result.isDenied) {
                resolve('no');
            }
        });
    });
}

export function FormErrorAlert(props) {

    return (


        <Alert style={{ width: '100%', display: "flex" }} variant={"danger"}>
            <svg style={{ height: '20px', marginRight: '8px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z" /></svg>
            <p style={{
                    wordWrap: 'break-word',
                    
                }}>{props.message}</p>
        </Alert>
    );
}

export function FormSuccessAlert(props) {

    return (


        <Alert style={{ width: '100%', display: "flex" }} variant={"success"}>
            <svg style={{ height: '20px', marginRight: '8px', marginTop: 2 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" /></svg>
            <p>{props.message}</p>
        </Alert>
    );
}

export function Loader(props) {

    return (


        <div style={{ display: "flex", justifyContent: "center" }}><PulseLoader color={props.color} /></div>
    );
}


export function LoadingSpinner(props) {

    return (

        <BeatLoader size={props.size} color={props.color} />
    );
}

